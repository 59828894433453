import { Observe } from "./chatbox/Observe";
import { ChatBox } from "./chatbox/ChatBox";
import { userAtom } from "./services/atoms";
import { useAtom } from "jotai";
import { useParams } from "react-router-dom";

export default () => {
  const [user] = useAtom(userAtom);
  const { id } = useParams();
  const alphabeticallyUsers = [user.id, id].sort((a, b) => a - b).join(",");
  return (
    <>
      <ChatBox parent_id={0} parent_type={alphabeticallyUsers} />
      <Observe
        parent_id={0}
        parent_type={alphabeticallyUsers}
        getName={() => "czat"}
      />
    </>
  );
};

// doNotShow={true}
