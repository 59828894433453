import { zipObj } from "ramda";
import { useEffect, useState } from "react";

const sendData = (ws, data) => {
  ws.send(JSON.stringify(data));
};

export const useTaskProvider = ({ roomId }) => {
  const [websocket, setWebsocket] = useState(false);
  const [room, setRoom] = useState(false);
  const [tasks, setTasks] = useState({});

  useEffect(() => {
    const websocket = new WebSocket("wss://room.prod.krzysztofsikorski.pl");
    setWebsocket(websocket);

    websocket.onmessage = (message) => {
      const { type, data } = JSON.parse(message.data);

      if (type === "room_done") {
        setRoom(data);
        setTasks({});
        sendData(websocket, {
          type: "listRecord",
        });
      }

      if (type === "updateRecord") {
        setTasks((tasks) => ({
          ...tasks,
          [data._id]: {
            ...tasks[data._id],
            [data.key]: data.value,
          },
        }));
      }

      if (type === "deleteRecord") {
        setTasks((tasks) => {
          delete tasks[data];
          console.log("[task-deleted]", tasks, data);
          return { ...tasks };
        });
      }

      if (type === "createRecords") {
        const newTasks = zipObj(
          data.map((task) => task._id),
          data.map((task) => task.content || {})
        );

        setTasks((tasks) => ({
          ...tasks,
          ...newTasks,
        }));
      }
    };

    websocket.onopen = () => {
      sendData(websocket, {
        type: "room",
        data: {
          userId: "qweasd",
          roomId,
        },
      });
    };
  }, []);

  return {
    room,
    tasks,
    sendData: (data) => sendData(websocket, data),
  };
};
