import { List } from "./services/List";

const TagQuery = `
query TagQuery($from: Int!, $search: String!) {
  records: tags(skip: $from, take: 10, where: {
    name: {
      contains: $search
    }
  }) {
    id
    name
    description
    genre
  }
  aggregation: aggregateTag(where: {
    name: {
      contains: $search
    }
  }) {
    _count {
      _all
    }
  }
}
`;

export default () => {
  return (
    <List
      mainKey={"name"}
      query={TagQuery}
      path="/tags"
      name="Tagi"
      plural="tagów"
      columns={(data) => (
        <>
          <div className="w-1/2 lg:w-2/12 px-4 mb-6 lg:mb-0">
            <a
              className="py-1 px-2 text-xs text-purple-500 bg-purple-50 rounded-full"
              href="#"
            >
              {data.genre}
            </a>
          </div>
        </>
      )}
    />
  );
};
