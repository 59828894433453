import {
  Input,
  Select,
  Submit,
  Textarea,
  TagSelect,
  DateSelect,
} from "./services/components";
import Toastify from "toastify-js";
import { useEffect, useState } from "react";
import Editor from "./services/Editor";
import { useGQLForm } from "./services/hooks";

const DataQuery = `
query ($id: Int!) {
    snippet(where: {
        id: $id
    }) {
      id
      name
      ad_or_not
      htmlContent
    }
  }
`;

const CreateQuery = `
mutation CreateSnippet($createData: SnippetCreateInput!) {
    createSnippet(data: $createData) {
      id
    }
  }
`;

const UpdateQuery = `
mutation UpdateSnippet($updateData: SnippetUpdateInput!, $id: Int!) {
    updateSnippet(data: $updateData, where: {
        id: $id
    }) {
      id
    }
  }
`;

const parseData = (data) => ({
  ...data,
  ad_or_not: parseInt(data.ad_or_not),
  type: "snippet",
});

export default () => {
  const {
    register,
    setValue,
    handleSubmit,
    onSubmit,
    error,
    data,
    idExists,
    id,
  } = useGQLForm({
    DataQuery,
    CreateQuery,
    UpdateQuery,
    parseData,
    path: "snippets",
    createKey: "createSnippet",
    afterSubmit: () => {
      Toastify({
        text: "Zapisano",
        gravity: "bottom",
      }).showToast();
    },
  });

  const getData = (key, def) => (idExists ? data.snippet[key] : def);

  useEffect(() => {
    if (!data && idExists) return () => {};

    register("htmlContent", {
      value: getData("htmlContent", "[]"),
    });
  }, [data]);

  if (error || (!data && idExists)) return <div>Loading...</div>;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container px-4 mx-auto py-8">
        <Input
          label="Nazwa"
          placeholder="Wpisz nazwę"
          {...register("name")}
          defaultValue={getData("name", "")}
        />

        <Input
          label="Reklama = 1, nie reklama = 0"
          placeholder="Wpisz reklamę"
          {...register("ad_or_not")}
          defaultValue={getData("ad_or_not", "")}
        />

        <div className="z-0 relative">
          <Editor
            onChange={(value) => setValue("htmlContent", value)}
            htmlContent={getData("htmlContent", "[]")}
          />
        </div>
        <Submit />
      </div>
    </form>
  );
};
