import { List } from "./services/List";

const PollQuery = `
query PollQuery($from: Int!, $search: String!) {
  records: polls(orderBy: {
    id: desc
  }, skip: $from, take: 10, where: {
    answers: {
      contains: $search
    }
  }) {
    id
    answers
  }
  aggregation: aggregatePoll(where: {
    answers: {
      contains: $search
    }
  }) {
    _count {
      _all
    }
  }
}
`;

export default () => {
  return (
    <List
      mainKey={"answers"}
      query={PollQuery}
      path="/polls"
      name="Sondy"
      plural="sond"
    />
  );
};
