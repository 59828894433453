import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "urql";
import { useParams } from "react-router-dom";
import { mapObjIndexed, pick } from "ramda";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

const DataQuery = `
query ($id: Int!) {
    metas(where: {
        article_id: {
          equals: $id
        }
    }) {
      id
      rocznice_data
      rocznice_lead
      onet_date
    }
  }
`;

const UpdateQuery = `
mutation UpdateMeta($data: MetaUpdateInput!, $id: Int!) {
    updateMeta(data: $data, where: {id: $id}) {
      article_id
    }
  }
`;

const CreateQuery = `
mutation CreateMeta($data: MetaCreateInput!) {
    createMeta(data: $data) {
      article_id
    }
  }
`;

export default () => {
  const { id } = useParams();
  const [{ data, loading, error }] = useQuery({
    query: DataQuery,
    variables: {
      id: parseInt(id),
    },
  });

  if (loading || error || !data) return <div>Ładowanie...</div>;
  console.log({ data, loading, error });
  return (
    <div>
      {data.metas.map((meta) => (
        <div>
          <Form meta={meta} id={meta.id} article_id={id} />
        </div>
      ))}
      Nowa:
      <div>
        <Form meta={{}} article_id={id} />
      </div>
    </div>
  );
};

const Form = ({ meta, article_id, id }) => {
  const [res1, updateMeta] = useMutation(UpdateQuery);
  const [res2, createMeta] = useMutation(CreateQuery);

  const { register, handleSubmit } = useForm({
    defaultValues: meta
      ? pick(["rocznice_data", "rocznice_lead", "onet_date"], meta)
      : {},
  });

  return (
    <form
      onSubmit={handleSubmit((data) => {
        if (!id) {
          createMeta({
            data: {
              ...data,
              article: {
                connect: {
                  id: parseInt(article_id),
                },
              },
            },
          });
        } else {
          updateMeta({
            id: parseInt(id),
            data: mapObjIndexed((val) => ({ set: val }), data),
          });
        }

        Toastify({
          text: "Zapisano",
          gravity: "bottom",
        }).showToast();
      })}
    >
      <div className="container px-4 mx-auto py-8">
        STRING ROCZNICY:
        <br />
        <input
          className="border-2 p-2"
          {...register("rocznice_data")}
          placeholder="MM-DD"
        />
        <br />
        DATA ONET:
        <br />
        <input
          className="border-2 p-2"
          {...register("onet_date")}
          placeholder="YYYY-MM-DD"
        />
        <br />
        LEAD REKLAMOWY: <br />
        <textarea
          {...register("rocznice_lead")}
          className="w-full border-2 p-2"
          rows={5}
          placeholder="jeśli pusty - lead tekstu jest brany"
        />
        <br />
        <input type="submit" />
      </div>
    </form>
  );
};
