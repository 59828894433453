import { Input, Select, Submit, Textarea } from "./services/components";
import { useGQLForm } from "./services/hooks";
import { useEffect } from "react";

const DataQuery = `
query ($id: Int!) {
    author(where: {
        id: $id
    }) {
      id
      fname
      biography
    }
  }
`;

const CreateQuery = `
mutation CreateAuthor($createData: AuthorCreateInput!) {
    createAuthor(data: $createData) {
      id
    }
  }
`;

const UpdateQuery = `
mutation UpdateAuthor($updateData: AuthorUpdateInput!, $id: Int!) {
    updateAuthor(data: $updateData, where: {
        id: $id
    }) {
      id
    }
  }
`;

const parseData = (data) => ({
  ...data,
});

export default () => {
  const {
    register,
    handleSubmit,
    onSubmit,
    error,
    data,
    idExists,
  } = useGQLForm({
    DataQuery,
    CreateQuery,
    UpdateQuery,
    parseData,
    createKey: "createAuthor",
    path: "authors",
  });

  useEffect(() => {
    register("author", {
      value: 1,
    });
  }, []);

  if (error || (!data && idExists)) return <div>Loading...</div>;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container px-4 mx-auto py-8">
        <div className="text-lg">
          {idExists ? "Edycja użytkownika" : "Tworzenie użytkownika"}
          <br />
        </div>

        <Input
          label="Nazwa"
          placeholder="Wpisz nazwę"
          {...register("fname")}
          defaultValue={idExists ? data.author.fname : ""}
        />

        <Textarea
          label="Treść autora"
          placeholder="Treść autora"
          {...register("biography")}
          defaultValue={idExists ? data.author.biography : ""}
        />

        <Submit />
      </div>
    </form>
  );
};
