import dayjs from "dayjs";
import { useEffect } from "react";
import { useQuery, useMutation } from "urql";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAtom } from "jotai";
import { userAtom } from "./services/atoms";

var utc = require("dayjs/plugin/utc");

dayjs.extend(utc);

const Comments = `{
    comments(where: {
        moderated: {
            equals: 0
        }
    }) {
        id
        nick
        content
        post
        ip
        created_at
        postRel {
          title
        }
    }
}`;

const ModerateComment = `mutation ModerateComment($shown: Int!, $commentId: Int!, $moderated: Int!) {
    updateComment(data: {
        moderated: {
            set: 1
        },
        shown: {
            set: $shown
        },
        moderated_by: {
          set: $moderated
        }
    }, where: {
        id: $commentId
    }) {
      id
    }
  }`;

const EditCommentMutation = `mutation EditComment($content: String!, $commentId: Int!) {
  updateComment(data: {
      content: {
          set: $content
      }
  }, where: {
      id: $commentId
  }) {
    id
  }
}`;

const EditComment = ({ initial, accept }) => {
  const [content, setContent] = useState(false);
  return (
    <>
      <textarea
        className="w-full"
        rows="6"
        value={content || initial}
        onChange={(e) => setContent(e.target.value)}
      />
      <button onClick={() => accept(content || initial)}>Save</button>
    </>
  );
};
export default () => {
  const [user] = useAtom(userAtom);
  const [{ data, error }, refetchComments] = useQuery({
    query: Comments,
    requestPolicy: "network-only",
  });

  const [_1, moderateComment] = useMutation(ModerateComment);
  const [_2, editComment] = useMutation(EditCommentMutation);

  useEffect(() => {
    setInterval(() => {
      console.log("refetch");
      refetchComments();
    }, 3000);
  }, []);

  if (!data) return <div>Ładowanie...</div>;
  return (
    <div>
      <Link to="/commentsModerated">ZMODEROWANE</Link>
      <br />
      <br />
      {data.comments.length === 0 && (
        <div>Wszystkie komentarze zmoderowane, jesteście zajebiści! :)</div>
      )}
      {data.comments.map((comment) => (
        <div>
          Data: {dayjs.utc(comment.created_at).format("YYYY-MM-DD HH:mm")}
          <br />
          Nick: {comment.nick}
          <br />
          IP: {comment.ip}
          <br />
          <a target="_blank" href={`https://histmag.org/?id=${comment.post}`}>
            {comment.postRel.title}
          </a>
          <br />
          Content:{" "}
          <EditComment
            accept={(content) =>
              editComment({
                commentId: comment.id,
                content,
              })
            }
            initial={comment.content}
          />
          <br />
          <br />
          <button
            className="border-2 p-4 mr-8"
            onClick={() =>
              moderateComment({
                shown: 1,
                commentId: comment.id,
                moderated: user.id,
              })
            }
          >
            OK
          </button>
          <button
            className="border-2 p-4"
            onClick={() =>
              moderateComment({
                shown: 0,
                commentId: comment.id,
                moderated: user.id,
              })
            }
          >
            wywalić
          </button>
          <br />
          <br />
          <hr />
          <br />
        </div>
      ))}
    </div>
  );
};
