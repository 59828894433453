import { Input, Select, Submit, Textarea } from "./services/components";
import { useGQLForm } from "./services/hooks";

const DataQuery = `
query ($id: Int!) {
    tag(where: {
        id: $id
    }) {
      id
      name
      genre
      description
      order
    }
  }
`;

const CreateQuery = `
mutation CreateTag($createData: TagCreateInput!) {
    createTag(data: $createData) {
      id
    }
  }
`;

const UpdateQuery = `
mutation UpdateTag($updateData: TagUpdateInput!, $id: Int!) {
    updateTag(data: $updateData, where: {
        id: $id
    }) {
      id
    }
  }
`;

const parseData = (data) => ({
  ...data,
  order: parseInt(data.order),
});

export default () => {
  const {
    register,
    handleSubmit,
    onSubmit,
    error,
    data,
    idExists,
  } = useGQLForm({
    DataQuery,
    CreateQuery,
    UpdateQuery,
    parseData,
    createKey: "createTag",
    path: "tags",
  });
  if (error || (!data && idExists)) return <div>Loading...</div>;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container px-4 mx-auto py-8">
        <Input
          label="Nazwa"
          placeholder="Wpisz nazwę"
          {...register("name")}
          defaultValue={idExists ? data.tag.name : ""}
        />

        <Select
          label="Rodzaj"
          {...register("genre")}
          defaultValue={idExists ? data.tag.genre : "Rodzaj"}
          options={["Rodzaj", "Tematyka", "Czas", "Terytorialne"]}
        />

        <Input
          label="Kolejność"
          placeholder="Wpisz kolejność (liczbę)"
          {...register("order")}
          defaultValue={idExists ? data.tag.order : "1"}
        />

        <Textarea
          label="Opis taga"
          placeholder="Opisz bardziej szczegółowo"
          {...register("description")}
          defaultValue={idExists ? data.tag.description : ""}
        />

        <Submit />
      </div>
    </form>
  );
};

/* <div className="mb-6">
<label className="block text-sm font-medium mb-2" for="">
  Label for textarea
</label>
<textarea
  className="block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
  name="field-name"
  rows="5"
  placeholder="Write something..."
></textarea>
</div>

<div className="mb-6">
<label>
  <input
    type="checkbox"
    name="field-name"
    value="example value"
    checked=""
  />
  <span className="ml-2">Check me out</span>
</label>
</div>

<div className="mb-6">
<div className="mb-1">
  <label>
    <input
      type="radio"
      name="radio-name"
      value="option 1"
      checked=""
    />
    <span className="ml-2">Radio option 1</span>
  </label>
</div>
<div>
  <label>
    <input type="radio" name="radio-name" value="option 2" />
    <span className="ml-2">Radio option 2</span>
  </label>
</div>
</div> */
