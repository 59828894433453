import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "urql";
import { useParams } from "react-router-dom";
import { mapObjIndexed } from "ramda";
import dayjs from "dayjs";
import { userAtom } from "./services/atoms";

const DataQuery = `
query ($date: String!) {
    metas(where: {
      rocznice_data: {contains: $date}
    }) {
      id
      article_id
      rocznice_lead
      rocznice_uwagi
      rocznice_status
      rocznice_redaktor
      rocznice_data_akceptacji
      rocznice_ulubione

      article {
        title
        excerpt
        slug
      }
    }
  }
`;

const UpdateQuery = `
mutation UpdateMeta($data: MetaUpdateInput!, $id: Int!) {
    updateMeta(data: $data, where: {id: $id}) {
      article_id
    }
  }
`;

// export const Record = ({ data, updateMeta }) => {
//   const { register, handleSubmit, reset } = useForm({
//     defaultValues: data,
//   });

//   return (
//     <form
//       onSubmit={handleSubmit((data) => {
//         const dataTransformed = {
//           ...data,
//         };
//         delete dataTransformed.__typename;
//         updateMeta({
//           id: data.article_id,
//           data: mapObjIndexed((val) => ({ set: val }), dataTransformed),
//         });
//       })}
//     >
//       <div className="container px-4 mx-auto py-8">
//         {data.godzinapublikacji}
//         <textarea {...register("uwagi")} placeholder="Uwagi" />
//         <input
//           {...register("potwierdzone")}
//           placeholder="Potwierdzenie inicjały"
//         />
//         <input type="submit" />
//       </div>
//     </form>
//   );
// };

export const RecordToCheck = ({
  data: {
    id,
    rocznice_lead,
    rocznice_uwagi,
    article_id,
    article: { title, excerpt, slug },
  },
  updateMeta,
  refetch,
}) => {
  const [popupShown, setPopupShown] = useState(false);
  const [uwagi, setUwagi] = useState("");
  const [user] = useAtom(userAtom);

  console.log(user);
  return (
    <>
      <div className="flex w-full flex-col lg:flex-row">
        <div className="w-full lg:w-1/3 p-2">
          <a
            className="underline"
            href={`https://histmag.org/${slug}-${article_id}`}
            target="_blank"
          >
            {title}
          </a>
        </div>
        <div
          className="w-full lg:w-2/3 p-2"
          dangerouslySetInnerHTML={{
            __html: (rocznice_lead || excerpt).replace(
              /(?:\r\n|\r|\n)/g,
              "<br>"
            ),
          }}
        />
        <div className="p-2">
          <button
            className="underline cursor-pointer"
            onClick={() => {
              setPopupShown(true);
            }}
          >
            Uwagi
          </button>
          <button
            className="underline cursor-pointer"
            onClick={() => {
              setUwagi("");
              setPopupShown(false);
              updateMeta({
                id,
                data: {
                  rocznice_data_akceptacji: {
                    set: dayjs().format("YYYY-MM-DD"),
                  },
                  rocznice_status: { set: "ZAAKCEPTOWANY" },
                  rocznice_redaktor: { set: user.name },
                },
              }).then(refetch);
            }}
          >
            Akceptuj
          </button>
        </div>
      </div>
      {popupShown && (
        <div className="z-50 fixed bottom-16 lg:bottom-0 left-0 w-full lg:left-1/4 lg:w-1/2 border-2 bg-white p-4">
          <div
            className="absolute top-0 right-0 w-4 h-4 bg-white cursor-pointer border-2"
            onClick={() => {
              setPopupShown(false);
            }}
          >
            X
          </div>
          Wpisz uwagi:
          <br />
          <textarea
            className="border-2 w-full"
            rows={5}
            value={uwagi}
            onChange={(e) => setUwagi(e.target.value)}
          />
          {uwagi.length > 0 && (
            <button
              className="underline"
              onClick={() => {
                updateMeta({
                  id,
                  data: {
                    rocznice_data_akceptacji: {
                      set: dayjs().format("YYYY-MM-DD"),
                    },
                    rocznice_uwagi: { set: uwagi },
                    rocznice_status: { set: "UWAGI" },
                    rocznice_redaktor: { set: user.name },
                  },
                }).then(() => {
                  refetch();
                  setUwagi("");
                  setPopupShown(false);
                });
              }}
            >
              Zapisz uwagi
            </button>
          )}
        </div>
      )}
    </>
  );
};

export const RecordUwagi = ({
  data: {
    id,
    rocznice_lead,
    rocznice_uwagi,
    article_id,
    article: { title, excerpt, slug },
  },
  updateMeta,
  refetch,
}) => {
  const [editedLead, setEditedLead] = useState(rocznice_lead || excerpt);
  return (
    <div className="flex w-full flex-col lg:flex-row">
      <div className="w-full lg:w-1/3 p-2">
        <a href={`https://histmag.org/${slug}-${article_id}`} target="_blank">
          {title}
        </a>
      </div>
      <div className="w-full lg:w-2/3 p-2">
        <textarea
          className="w-full"
          value={editedLead}
          onChange={(e) => setEditedLead(e.target.value)}
          rows={4}
        />
        <br />
        Uwagi:{" "}
        <div
          dangerouslySetInnerHTML={{
            __html: rocznice_uwagi.replace(/(?:\r\n|\r|\n)/g, "<br>"),
          }}
        />
      </div>
      <div className="p-2">
        <button
          className="underline cursor-pointer"
          onClick={() => {
            updateMeta({
              id,
              data: {
                rocznice_status: { set: "" },
              },
            }).then(refetch);
          }}
        >
          Cofnij uwagi
        </button>
        <button
          className="underline cursor-pointer"
          onClick={() => {
            updateMeta({
              id,
              data: {
                rocznice_data: { set: "odrzucony" },
                rocznice_status: { set: "" },
              },
            }).then(refetch);
          }}
        >
          Wywal
        </button>
        <button
          className="underline cursor-pointer"
          onClick={() => {
            updateMeta({
              id,
              data: {
                rocznice_lead: { set: editedLead },
                rocznice_status: { set: "ZAAKCEPTOWANY" },
              },
            }).then(refetch);
          }}
        >
          Akceptuj
        </button>
      </div>
    </div>
  );
};

export const RecordChecked = ({
  data: {
    id,
    rocznice_lead,
    rocznice_redaktor,
    rocznice_data_akceptacji,
    rocznice_ulubione,
    article_id,
    article: { title, excerpt, slug },
  },
  updateMeta,
  refetch,
}) => {
  const [cofnij, setCofnij] = useState(false);
  return (
    <div className="flex w-full flex-col lg:flex-row">
      <div className="w-full lg:w-1/3 p-2">
        <a href={`https://histmag.org/${slug}-${article_id}`} target="_blank">
          {title}
        </a>
      </div>
      <div className="w-full lg:w-2/3 p-2">
        <div
          dangerouslySetInnerHTML={{
            __html: (rocznice_lead || excerpt).replace(
              /(?:\r\n|\r|\n)/g,
              "<br>"
            ),
          }}
        />
        <br />
        <br />
        przez {rocznice_redaktor} ({rocznice_data_akceptacji})
      </div>
      <div
        className="underline cursor-pointer p-2"
        onClick={() => {
          navigator.clipboard.writeText(
            `${
              rocznice_lead || excerpt
            }\n\nhttps://histmag.org/${slug}-${article_id}`
          );
        }}
      >
        Skopiuj
      </div>
      <div
        className={
          "underline cursor-pointer p-2" +
          (rocznice_ulubione ? " font-bold" : "")
        }
        onClick={() => {
          updateMeta({
            id,
            data: {
              rocznice_ulubione: { set: rocznice_ulubione ? 0 : 1 },
            },
          }).then(refetch);
        }}
      >
        Ulubione
      </div>
      {!cofnij && (
        <div
          onClick={() => {
            setCofnij(true);
          }}
          className="underline cursor-pointer p-2"
        >
          Cofnij
        </div>
      )}
      {cofnij && (
        <div
          className="underline cursor-pointer p-2"
          onClick={() => {
            updateMeta({
              id,
              data: {
                rocznice_status: { set: "" },
              },
            }).then(refetch);
          }}
        >
          Na pewno?
        </div>
      )}
    </div>
  );
};

export default () => {
  const [user] = useAtom(userAtom);
  const { date } = useParams();
  const [res1, updateMeta] = useMutation(UpdateQuery);
  const [{ data, loading, error }, refetch] = useQuery({
    query: DataQuery,
    variables: {
      date,
    },
  });

  if (loading || error || !data) return <div>Ładowanie...</div>;

  const metas = data.metas;
  const [toCheck, withUwagi, accepted] = [
    metas.filter((el) => !el.rocznice_status),
    metas.filter((el) => el.rocznice_status === "UWAGI"),
    metas.filter((el) => el.rocznice_status === "ZAAKCEPTOWANY"),
  ];

  return (
    <div className="p-8">
      <h2 className="font-bold text-2xl">Wpisy na {date}</h2>
      <h3 className="font-bold text-lg mt-4">Do sprawdzenia</h3>
      <div className="border-2 my-2">
        {toCheck.map((meta, ind) => (
          <RecordToCheck
            key={meta.article_id}
            ind={ind}
            data={meta}
            updateMeta={updateMeta}
            refetch={refetch}
          />
        ))}
      </div>
      <button
        className="underline cursor-pointer"
        onClick={() => {
          Promise.all(
            toCheck.map((entry) =>
              updateMeta({
                id: entry.id,
                data: {
                  rocznice_status: { set: "ZAAKCEPTOWANY" },
                  rocznice_redaktor: { set: user.name },
                },
              })
            )
          ).then(refetch);
        }}
      >
        Akceptuj wszystkie
      </button>

      <h3 className="font-bold text-lg mt-4">Z uwagami</h3>
      <div className="border-2 my-2">
        {withUwagi.map((meta) => (
          <RecordUwagi
            key={meta.article_id}
            data={meta}
            updateMeta={updateMeta}
            refetch={refetch}
          />
        ))}
      </div>

      <h3 className="font-bold text-lg mt-4">Zatwierdzone</h3>
      <div className="border-2 my-2">
        {accepted
          .sort((a, b) => b.rocznice_ulubione - a.rocznice_ulubione)
          .map((meta) => (
            <RecordChecked
              key={meta.article_id}
              data={meta}
              updateMeta={updateMeta}
              refetch={refetch}
            />
          ))}
      </div>
    </div>
  );
};
