import { List } from "./services/List";

const SocialLinksQuery = `
query SocialLinksQuery($from: Int!, $search: String!) {
  records: findManySocialLinks(skip: $from, take: 10, where: {
    link: {
      contains: $search
    }
  }) {
    id
    link
    title
    description
    featured_image_base
  }
  aggregation: aggregateSocialLinks(where: {
    link: {
      contains: $search
    }
  }) {
    _count {
      _all
    }
  }
}
`;

export const SocialLinks = () => {
  return (
    <List
      mainKey={"link"}
      query={SocialLinksQuery}
      path="/social_links"
      name="SocialLinki"
      plural="linków"
    />
  );
};
