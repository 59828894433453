export default () => {
  console.log(window.location.search);
  return (
    <div
      className="flex stretch w-full"
      style={{
        height: "calc(100vh - 96px)",
      }}
    >
      <iframe
        className="w-full"
        src={`https://histmag.org/${window.location.search.slice(5)}`}
      />
      <iframe
        className="w-full"
        src={`https://asdkjowenmrjiqeqwe.histmag.org/${window.location.search.slice(
          5
        )}`}
      />
    </div>
  );
};
