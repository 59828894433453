import { useEffect, useState } from "react";
import { Input, Select, Submit, Textarea } from "./services/components";
import { useGQLForm } from "./services/hooks";

const DataQuery = `
query ($id: Int!) {
  findUniqueSocialLinks(where: {
        id: $id
    }) {
      id
      link
      title
      description
      featured_image_base
      featured_image_instructions
    }
  }
`;

const CreateQuery = `
mutation CreateSocialLinks($createData: SocialLinksCreateInput!) {
    createSocialLinks(data: $createData) {
      id
    }
  }
`;

const UpdateQuery = `
mutation UpdateSocialLinks($updateData: SocialLinksUpdateInput!, $id: Int!) {
    updateSocialLinks(data: $updateData, where: {
        id: $id
    }) {
      id
    }
  }
`;

const parseData = (data) => ({
  ...data,
});

export const SocialLink = () => {
  const {
    register,
    handleSubmit,
    onSubmit,
    error,
    data,
    idExists,
    id,
    setValue,
    getValues,
  } = useGQLForm({
    DataQuery,
    CreateQuery,
    UpdateQuery,
    parseData,
    createKey: "createSocialLinks",
    path: "findUniqueSocialLinks",
  });

  const getData = (key, def) =>
    idExists ? data.findUniqueSocialLinks[key] : def;

  const downloadData = async () => {
    const link = getValues("link");
    console.log("[LINK]", link);
    const res = await (
      await fetch(`https://api.histmag.org/extract?url=${link}`)
    ).json();
    console.log("[res]", res);
    setValue("title", res.title);
    setValue("description", res.description);
    setValue("featured_image_base", res.image);
  };

  if (error || (!data && idExists)) return <div>Loading...</div>;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container px-4 mx-auto py-8">
        <div
          onClick={() => {
            const link = getValues("link");
            const slug = link.replace("https://histmag.org", "");
            navigator.clipboard.writeText(
              `https://sm.histmag.org/api${slug}-${id}\n\n${getValues(
                "description"
              )}`
            );
          }}
        >
          Skopiuj
        </div>
        <Input
          label="Link"
          placeholder="Wpisz link"
          {...register("link")}
          defaultValue={getData("link", "")}
        />
        <br />
        <div onClick={downloadData}>Download</div>
        <br />
        <Input
          label="Tytuł"
          placeholder="Wpisz tytuł"
          {...register("title")}
          defaultValue={getData("title", "")}
        />
        <br />
        <br />

        <Textarea
          label="Description"
          placeholder="Wpisz description"
          {...register("description")}
          defaultValue={getData("description", "")}
        />
        <br />
        <br />

        <Input
          label="Featured Image"
          placeholder="Wpisz featured image"
          {...register("featured_image_base")}
          defaultValue={getData("featured_image_base", "")}
        />
        <br />
        <br />

        <Submit />
      </div>
    </form>
  );
};
