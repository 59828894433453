import ky from "ky";
import unified from "unified";
import markdown from "remark-parse";
import { useState, useEffect } from "react";
import cheerio from "cherio";
import Undo from "editorjs-undo";
import { Footnote, FootnoteMaker } from "editorjs-footnotes";
import remarkrehype from "remark-rehype";

import EditorJS from "@editorjs/editorjs";
import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import Paragraph from "@editorjs/paragraph";
import List from "@editorjs/list";
import Warning from "@editorjs/warning";
import Code from "@editorjs/code";
import LinkTool from "@editorjs/link";
import Image from "@editorjs/image";
import Raw from "@editorjs/raw";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import SimpleImage from "@krzysztof_sikorski/simple-image";
import { omit } from "ramda";
import { LinkInlineTool } from "./InlineLink";

const ChangeFontSize = require("@quanzo/change-font-size");

const cleanAdditionalSpaces = (text) =>
  text
    .replace(/\s\s/g, " ")
    .replace(/\(\s/gs, "(")
    .replace(/\s\)/gs, ")")
    .replace(/\[\s/gs, "[")
    .replace(/\s\]/gs, "]")
    .replace(/\s,/g, ",")
    .replace(/\s\./g, ".");
class OldCode {
  constructor({ data }) {
    this.data = data;
  }
  static get toolbox() {
    return {
      title: "OldCode",
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
    };
  }

  render() {
    const wrapper = document.createElement("div"),
      title = document.createElement("span"),
      content = document.createElement("textarea");

    content.setAttribute("id", "content");
    content.setAttribute("style", "width:100%;height:300px");
    content.value = this.data && this.data.content ? this.data.content : "";

    title.textContent = "OLDCODE";
    wrapper.appendChild(title);
    wrapper.appendChild(content);

    return wrapper;
  }

  save(blockContent) {
    const content = blockContent.querySelector("#content");

    return {
      content: content.value,
    };
  }
}

class Quiz {
  constructor({ data }) {
    this.data = data;
  }
  static get toolbox() {
    return {
      title: "Quiz",
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
    };
  }

  render() {
    const wrapper = document.createElement("div"),
      title = document.createElement("span"),
      content = document.createElement("textarea");

    content.setAttribute("id", "content");
    content.setAttribute("style", "width:100%;height:300px");
    content.value = this.data && this.data.content ? this.data.content : "";

    title.textContent = "QUIZ";
    wrapper.appendChild(title);
    wrapper.appendChild(content);

    return wrapper;
  }

  save(blockContent) {
    const content = blockContent.querySelector("#content");

    return {
      content: content.value,
    };
  }
}

class SEOIndex {
  constructor({ data }) {
    this.data = data;
  }
  static get toolbox() {
    return {
      title: "SEOIndex",
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
    };
  }

  render() {
    const wrapper = document.createElement("div"),
      title = document.createElement("span");

    title.textContent = "SEOINDEX";
    wrapper.appendChild(title);

    return wrapper;
  }

  save() {
    return {};
  }
}
class Book extends OldCode {
  static get toolbox() {
    return {
      title: "Książka",
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
    };
  }

  render() {
    const wrapper = document.createElement("div"),
      title = document.createElement("div"),
      content = document.createElement("textarea");

    content.setAttribute("id", "content");
    content.setAttribute("style", "width:100%;height:300px");
    content.value = this.data && this.data.content ? this.data.content : "";

    title.textContent = "BOOKCODE";
    wrapper.appendChild(title);
    wrapper.appendChild(content);

    return wrapper;
  }
}

class Gallery extends OldCode {
  static get toolbox() {
    return {
      title: "Galeria",
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
    };
  }

  render() {
    const wrapper = document.createElement("div"),
      title = document.createElement("div"),
      content = document.createElement("textarea");

    content.setAttribute("id", "content");
    content.setAttribute("style", "width:100%;height:200px");
    content.value = this.data && this.data.content ? this.data.content : "";

    title.textContent = "Galeria - kod";
    wrapper.appendChild(title);
    wrapper.appendChild(content);

    return wrapper;
  }
}

class Konkurs extends OldCode {
  static get toolbox() {
    return {
      title: "Konkurs",
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
    };
  }

  render() {
    const wrapper = document.createElement("div"),
      title = document.createElement("div"),
      content = document.createElement("textarea");

    content.setAttribute("id", "content");
    content.setAttribute("style", "width:100%;height:200px");
    content.value = this.data && this.data.content ? this.data.content : "";

    title.textContent = "Konkurs - kod";
    wrapper.appendChild(title);
    wrapper.appendChild(content);

    return wrapper;
  }
}

class EmbedText {
  constructor({ data }) {
    this.data = data;
  }
  info() {
    return "EMBEDFULL";
  }
  static get toolbox() {
    return {
      title: "EmbedText",
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
    };
  }

  render() {
    const wrapper = document.createElement("div"),
      title = document.createElement("span"),
      id = document.createElement("input");

    id.setAttribute("id", "id");
    id.value = this.data && this.data.id ? this.data.id : "";

    title.textContent = this.info ? this.info() : "EMBEDFULL";
    wrapper.appendChild(title);
    wrapper.appendChild(id);

    return wrapper;
  }

  save(blockContent) {
    const id = blockContent.querySelector("#id");

    return {
      id: id.value,
    };
  }
}

class SnippetDelimiter extends Delimiter {
  info() {
    return "SNIPPETDELIMITER";
  }
  render() {
    const wrapper = document.createElement("div");
    wrapper.innerText = "SNIPPEETDELIMITER";
    return wrapper;
  }
  static get toolbox() {
    return {
      title: "SNIPPETDELIMITER",
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
    };
  }
}

class EmbedHalf extends EmbedText {
  info() {
    return "EMBEDHALF";
  }
  static get toolbox() {
    return {
      title: "EmbedHalf",
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
    };
  }
}

class Embeder extends EmbedText {
  info() {
    return "EMBEDER";
  }
  static get toolbox() {
    return {
      title: "Embeder",
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
    };
  }
}

class Snippet extends EmbedText {
  info() {
    return "SNIPPET";
  }
  static get toolbox() {
    return {
      title: "Snippet",
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
    };
  }
}

class Buybox extends EmbedText {
  info() {
    return "BUYBOX";
  }
  static get toolbox() {
    return {
      title: "BUYBOX",
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
    };
  }
}

export const EDITOR_JS_TOOLS = {
  embedFull: EmbedText,
  embedHalf: EmbedHalf,
  embeder: Embeder,
  snippet: Snippet,
  buybox: Buybox,
  gallery: Gallery,
  konkurs: Konkurs,
  embed: Embed,
  // table: Table,
  // paragraph: Paragraph,
  list: {
    class: List,
    inlineToolbar: true,
  },
  // warning: Warning,
  // code: Code,
  // linkTool: LinkTool,
  image: SimpleImage,
  raw: Raw,
  header: {
    class: Header,
    inlineToolbar: true,
  },
  quote: {
    class: Quote,
    inlineToolbar: true,
  },
  // marker: Marker,
  // checklist: CheckList,
  delimiter: Delimiter,
  snippetDelimiter: SnippetDelimiter,
  inlineCode: InlineCode,
  // simpleImage: SimpleImage,
  book: Book,
  oldCode: OldCode,
  seoIndex: SEOIndex,
  quiz: Quiz,
  smallerText: {
    class: ChangeFontSize,
    config: {
      cssClass: "smallerText",
      buttonText: "sm",
    },
  },
  link: LinkInlineTool,
  // FootnoteMaker,
  // footnoteParagraph: {
  //   class: Footnote,
  //   inlineToolbar: ["link", "bold", "italic"],
  // },
};

const processor = unified().use(markdown).use(remarkrehype, {
  allowDangerousHTML: true,
  commonmark: true,
});

const clean = (text) =>
  text
    ? text
    : // .replace(/<imager/g, "%imager")
      // .replace(/<\/imager/g, "%/imager")
      // .replace(/<embedfull/g, "%embedfull")
      // .replace(/<\/embedfull/g, "%/embedfull")
      // .replace(/<\/embedhalf/g, "%/embedhalf")
      "";

const parseToText = (el) => {
  console.log(el);
  const { type, value, url, children } = el;
  if (type === "paragraph" || type === "listItem")
    return children.map(parseToText).join("");
  if (type === "emphasis")
    return `<i>${children.map(parseToText).join("")}</i>`;
  if (type === "strong") return `<b>${children.map(parseToText).join("")}</b>`;
  if (type === "text") return value;
  if (type === "html") return value;
  if (type === "link")
    return `<a href="${url}">${children.map(parseToText).join("")}</a>`;
  if (type === "image") return `<img src="${url}" />`;
};

function App({ htmlContent, content, getEditor, ...restProps }) {
  console.log(htmlContent);
  console.log(content);
  console.log(restProps);
  // const [editorInstance, setEditorInstance] = useState(false);
  //   const [editorData, setEditorData] = useState(false);
  //   const [html, setHtml] = useState(false);
  // const [link, setLink] = useState("");
  const [editor, setEditor] = useState(null);
  const [startingData, setStartingData] = useState(false);

  const getData = async () => {
    if (htmlContent) {
      console.log(JSON.parse(htmlContent));
      setStartingData({
        blocks: JSON.parse(htmlContent),
      });
    } else {
      setStartingData({
        blocks: [],
      });
    }
    return;
  };

  useEffect(() => {
    if (!startingData) return () => {};
    let interval = false;
    const editor = new EditorJS({
      holder: "editorjs",
      tools: EDITOR_JS_TOOLS,
      data: startingData,
      placeholder: "Let`s write an awesome story!",
      // onReady: () => {
      // new Undo({ editor });
      // },
      onChange: () => {
        editor.save().then((output) => {
          restProps.onChange(JSON.stringify(output.blocks));
        });
      },
    });
    setEditor(editor);
    if (getEditor) getEditor(editor);
  }, [startingData]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div
        id="editorjs"
        className="prose border-2 w-full"
        style={{
          width: "100%",
          maxWidth: "100%",
          minHeight: 800,
        }}
      ></div>
    </>
  );
}

export default App;
// ^1.9.0
