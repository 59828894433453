import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { useQuery, useMutation } from "urql";
import { usersAtom, userAtom } from "../services/atoms";

export const Observe = ({
  parent_id,
  parent_type,
  getName,
  doNotShow = false,
}) => {
  const [users] = useAtom(usersAtom);
  const [user] = useAtom(userAtom);
  // const members =

  const dataString = `
  parent_id: ${parent_id},
    parent_type: "${parent_type}",
    user_id: ${user.id}
    `;

  const whereString = `parent_id_parent_type_user_id: {
    ${dataString}
  }`;

  const [{ data }, refetch] = useQuery({
    query: `{
        notification(where: {
          ${whereString}
        }) {
          number
        }
      }`,
    requestPolicy: "network-only",
  });

  const [_1, setZero] = useMutation(`
    mutation {
        updateNotification(where: {
          ${whereString}
        }, data: {
          number: {
            set: 0
          }
        }) {
          number
        }
      }
  `);

  const [_2, observe] = useMutation(`
    mutation {
        createNotification(data: {
            ${dataString},
            name: "${getName()}",
            number: 0
        }) {
          number
        }
      }
    `);

  const [_3, unobserve] = useMutation(`
mutation {
deleteNotification (where: {
    ${whereString}
  }) {
    number
  }
}
`);

  useEffect(() => {
    const interval = setInterval(() => {
      setZero();
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!data) return null;

  if (doNotShow) return null;

  console.log(data);
  return (
    <div>
      {data.notification ? (
        <div
          onClick={() => {
            unobserve().then(refetch);
          }}
        >
          Przestań obserwować
        </div>
      ) : (
        <div
          onClick={() => {
            observe().then(refetch);
          }}
        >
          Obserwuj
        </div>
      )}
    </div>
  );
};
