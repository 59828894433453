import { useEffect, useState } from "react";
import { Input, Select, Submit, Textarea } from "./services/components";
import { useGQLForm } from "./services/hooks";

const DataQuery = `
query ($id: Int!) {
    poll(where: {
        id: $id
    }) {
      id
      answers
    }
  }
`;

const CreateQuery = `
mutation CreatePoll($createData: PollCreateInput!) {
    createPoll(data: $createData) {
      id
    }
  }
`;

const UpdateQuery = `
mutation UpdatePoll($updateData: PollUpdateInput!, $id: Int!) {
    updatePoll(data: $updateData, where: {
        id: $id
    }) {
      id
    }
  }
`;

const parseData = (data) => ({
  ...data,
});

export default () => {
  const [pollResults, setPollResults] = useState(false);
  const {
    register,
    handleSubmit,
    onSubmit,
    error,
    data,
    idExists,
    id,
  } = useGQLForm({
    DataQuery,
    CreateQuery,
    UpdateQuery,
    parseData,
    createKey: "createPoll",
    path: "polls",
  });

  useEffect(() => {
    if (!idExists) return () => {};

    fetch("https://service.histmag.org/pollResults/" + id)
      .then((response) => response.json())
      .then((data) => {
        console.log("[data]", data);
        setPollResults(data);
      });
  }, []);

  if (error || (!data && idExists)) return <div>Loading...</div>;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container px-4 mx-auto py-8">
        <Textarea
          label="Treść sondy"
          placeholder="Treść sondy"
          {...register("answers")}
          defaultValue={idExists ? data.poll.answers : ""}
        />

        {pollResults && (
          <ul>
            {pollResults.votes.map((vote, ind) => {
              return (
                <li>
                  {vote} - {pollResults.results[ind]}
                </li>
              );
            })}
          </ul>
        )}
        <br />
        <br />

        <Submit />
      </div>
    </form>
  );
};
