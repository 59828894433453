import { useGQLForm } from "./services/hooks";
import { useState } from "react";
import { ChatBox } from "./chatbox/ChatBox";
import { Observe } from "./chatbox/Observe";
import { useAtom } from "jotai";
import { mobileModeAtom } from "./services/atoms";
import { NewTasklist } from "./newtasks/NewTaskList";

const DataQuery = `
query ($id: Int!) {
    issue(where: {
        id: $id
    }) {
      id
      name
      wiki
    }
  }
`;
const CreateQuery = ``;
const UpdateQuery = ``;

export default () => {
  const [mobileMode] = useAtom(mobileModeAtom);
  const [activeTab, setActiveTab] = useState("tasks");

  const { error, data, idExists, id } = useGQLForm({
    DataQuery,
    CreateQuery,
    UpdateQuery,
    createKey: "createAuthor",
    path: "authors",
  });

  if (error || (!data && idExists)) return <div>Loading...</div>;

  return (
    <>
      {mobileMode ? (
        <div className="w-full h-screen">
          <div className="w-full">
            <h1 className="text-2xl text-bold p-8 text-center">
              {data.issue.name}
            </h1>
          </div>
          <div className="flex">
            <div
              onClick={() => setActiveTab("tasks")}
              className={`p-4 w-full text-center ${
                activeTab === "tasks" ? "font-bold" : ""
              }`}
            >
              Zadania
            </div>
            <div
              onClick={() => setActiveTab("chat")}
              className={`p-4 w-full text-center ${
                activeTab === "chat" ? "font-bold" : ""
              }`}
            >
              Czat
            </div>
            <div
              onClick={() => setActiveTab("wiki")}
              className={`p-4 w-full text-center ${
                activeTab === "wiki" ? "font-bold" : ""
              }`}
            >
              Wiki
            </div>
          </div>
          {activeTab === "tasks" && <NewTasklist roomId={"praktyki_1"} />}
          {activeTab === "chat" && (
            <div>
              <ChatBox
                taskMode
                parent_id={data.issue.id}
                parent_type="issues"
              />
            </div>
          )}
          {activeTab === "wiki" && (
            <div>
              <iframe
                src={`https://etherpad.dev.krzysztofsikorski.pl/p/issue_${id}?showChat=false&showLineNumbers=false`}
                height={500}
                style={{
                  width: "100%",
                }}
              ></iframe>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="w-full">
            <h1 className="text-2xl text-bold p-8 text-center">
              {data.issue.name}
            </h1>
            <Observe
              parent_id={data.issue.id}
              parent_type={"issues"}
              getName={() => data.issue.name}
              doNotShow={true}
            />
          </div>
          <div className="m-4 p-4">
            <NewTasklist roomId={"praktyki_1"} />
          </div>
          <div className="flex">
            <div className="w-2/3">
              <div className="m-4 border-2">
                <iframe
                  src={`https://etherpad.dev.krzysztofsikorski.pl/p/issue_${id}?showChat=false&showLineNumbers=false`}
                  height={500}
                  style={{
                    width: "100%",
                  }}
                ></iframe>
              </div>
            </div>
            <div className="w-1/3">
              <div className="m-4 border-2">
                <ChatBox
                  taskMode
                  parent_id={data.issue.id}
                  parent_type="issues"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
