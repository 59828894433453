import { List } from "./services/List";

const ContactQuery = `
query ContactQuery($from: Int!, $search: String!) {
  records: contacts(skip: $from, take: 10, where: {
    name: {
      contains: $search
    }
  }) {
    id
    name
  }
  aggregation: aggregateContact(where: {
    name: {
      contains: $search
    }
  }) {
    _count {
      _all
    }
  }
}
`;

export default () => {
  return (
    <List
      mainKey={"name"}
      query={ContactQuery}
      path="/contacts"
      name="Kontakty"
      plural="kontaktów"
    />
  );
};
