import { List } from "./services/List";
import dayjs from "dayjs";
import { useMutation } from "urql";
import { useAtom } from "jotai";
import { reklamodawcyAtom, userAtom, usersAtom } from "./services/atoms";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("Europe/Warsaw");

console.log("[dayjs]", dayjs().format("YYYY-MM-DDTHH:mm:ssZ"));

const ArticleQuery = `
query ArticleQuery($from: Int!, $search: String!, $reklamodawca: [String!], $created_by: [Int!]) {
  records: articles(orderBy: {id: desc}, skip: $from, take: 30, where: {
    title: {
      contains: $search
    },
    deletedCMS: {
      equals: 0
    },
    reklamodawca: {
      in: $reklamodawca 
    },
    created_by: {
      in: $created_by
    }
  }) {
    id
    title
    status
    slug
    authors {
      user {
        fname
      }
    }
  }

  aggregation: aggregateArticle(where: {
    title: {
      contains: $search
    },
    reklamodawca: {
      in: $reklamodawca 
    },
    created_by: {
      in: $created_by
    },
    deletedCMS: {
      equals: 0
    }
  }) {
    _count {
      _all
    }
  }
}
`;

export const CreateArticle = `
mutation CreateArticle($data: ArticleCreateInput!) {
  createArticle(data: $data) {
    id
  }
}
`;

const DeleteArticle = `
mutation DeleteArticle($id: Int) {
  updateArticle(where: {
    id: $id
  },
    data: {
      deletedCMS: {set: 1}
    }
) {
    id
  }
}
`;

export const startingData = (created_by) => {
  return {
    data: {
      title: "",
      excerpt: "",
      slug: "",
      fb_image: "",
      license: "copy",
      published_at: dayjs().format("YYYY-MM-DDTHH:mm:ss") + ".000Z",
      first_publish: dayjs().format("YYYY-MM-DDTHH:mm:ss") + ".000Z",
      auto_ads: 1,
      status: "draft",
      content: "jeśli widzisz tę wiadomość, zgłoś to na admin@histmag.org",
      created_by,
      htmlContent: JSON.stringify([
        {
          id: "ze7mzgeGPI",
          type: "paragraph",
          data: { text: "Let's write an awesome story" },
        },
      ]),
    },
  };
};

export default () => {
  const [reklamodawcy] = useAtom(reklamodawcyAtom);
  const [users] = useAtom(usersAtom);

  const [res1, createMutation] = useMutation(CreateArticle);
  const [res2, deleteMutation] = useMutation(DeleteArticle);

  const [user] = useAtom(userAtom);

  console.log("[user]", user);

  return (
    <>
      <List
        mainKey={"title"}
        query={ArticleQuery}
        path="/articles"
        name="Artykuły"
        plural="artykułów"
        pageSize={30}
        createAction={() => {
          createMutation(startingData(user.id)).then((data) => {
            console.log(data);
            window.location.href = `/articles/${data.data.createArticle.id}`;
          });
        }}
        filters={[
          {
            field: "reklamodawca",
            values: reklamodawcy.map((e) => ({
              value: e.reklamodawca,
              label: e.reklamodawca,
            })),
            parse: (e) => e,
          },
          {
            field: "created_by",
            values: [
              ...users.map((e) => ({ value: e.id, label: e.name })),
              {
                value: 0,
                label: "Nikt",
              },
            ],
            parse: (e) => parseInt(e),
          },
        ]}
        columns={(data) => (
          <>
            <div className="w-1/2 lg:w-2/12 px-4 mb-6 lg:mb-0">
              <a
                className="py-1 px-2 text-xs text-purple-500 bg-purple-50 rounded-full"
                href="#"
              >
                {data.status}
              </a>
            </div>
            <div className="w-1/2 lg:w-2/12 px-4 mb-6 lg:mb-0 text-xs">
              {data.authors.map((author) => author.user.fname).join(", ")}
            </div>
            <div className="w-1/2 lg:w-2/12 px-4 mb-6 lg:mb-0">
              {data.title ? (
                <a
                  target="_blank"
                  className="py-1 px-2 text-xs text-purple-500 bg-purple-50 rounded-full"
                  href={`https://histmag.org/${data.slug}-${data.id}`}
                >
                  tekst
                </a>
              ) : (
                <span
                  onClick={() => {
                    deleteMutation({
                      id: data.id,
                    });
                  }}
                >
                  Usuń z CMS
                </span>
              )}
            </div>
          </>
        )}
      />
    </>
  );
};
