import { useCallback, useEffect, useRef, useState } from "react";
import { atom, useAtom } from "jotai";
import dayjs from "dayjs";
import { currentTaskAtom } from "./services/atoms";

// const readsAtom = atom({});
// const typingAtom = atom({});
// const messagesAtom = atom([]);
// const authAtom = atom(false);
// const roomLoadedAtom = atom(false);
// const roomAtom = atom(false);
// const websocketAtom = atom(false);

// Utils
const sendData = (ws, data) => {
  ws.send(JSON.stringify(data));
};

const sortMessages = (messageA, messageB) =>
  messageA.createdAt.localeCompare(messageB.createdAt);

// hookProvider
export const useChatProvider = ({ userId, roomId, currentTask = false }) => {
  const [room, setRoom] = useState(false);
  const [messages, setMessages] = useState([]);
  const [reads, setReads] = useState({});
  const [typing, setTyping] = useState({});
  const [websocket, setWebsocket] = useState(false);
  const [roomLoaded, setRoomLoaded] = useState(false);

  const additionalFilter = currentTask
    ? {
        ["content.task_id"]: currentTask,
      }
    : {};
  console.log(additionalFilter);

  const scrollContainerRef = useCallback((element) => {
    if (element === null) return;
    element.scrollTop = element.scrollHeight;
  });

  const scrollFunction = (event) => {
    if (event.target.scrollTop === 0) {
      loadMore();
    }
  };

  const loadMore = () => {
    const firstMessage = messages[0];
    sendData(websocket, {
      type: "getMessages",
      data: {
        lastSeen: firstMessage ? firstMessage.createdAt : false,
        additionalFilter,
      },
    });
  };

  const sendMessage = (content) => {
    sendData(websocket, {
      type: "message",
      data: content,
    });
  };

  const sendTyping = () => {
    sendData(websocket, {
      type: "typing",
    });
  };

  useEffect(() => {
    setMessages([]);
    setReads({});
    setTyping({});
    setRoomLoaded(false);

    const websocket = new WebSocket("wss://chat.prod.krzysztofsikorski.pl");
    // const websocket = new WebSocket("ws://localhost:8080");

    websocket.onmessage = (message) => {
      console.log("[message]", message);
      const { type, data } = JSON.parse(message.data);

      if (type === "room_done") {
        setRoom(data);
        sendData(websocket, { type: "getReads" });
        sendData(websocket, {
          type: "getMessages",
          data: {
            additionalFilter,
          },
        });
      }

      if (type === "message" || type === "first_message") {
        setMessages((messages) =>
          messages
            .concat(data)
            .filter(
              (message) =>
                !currentTask || currentTask === message.content.task_id
            )
            .sort(sortMessages)
        );
      }

      if (type === "first_message") {
        setRoomLoaded(true);
      }

      if (type === "read") {
        setReads((reads) => ({
          ...reads,
          data,
        }));
      }
      if (type === "typing") {
        setTyping((typing) => ({
          ...typing,
          [data]: dayjs().toISOString(),
        }));
      }
    };

    websocket.onopen = () => {
      sendData(websocket, {
        type: "room",
        data: {
          userId,
          roomId,
        },
      });
    };
    setWebsocket(websocket);
  }, [currentTask]);

  return {
    messages,
    reads,
    typing,
    websocket,
    isLoaded: roomLoaded,

    sendTyping,
    sendMessage,
    scrollFunction,
    scrollContainerRef,
  };
};

// Example components

const Message = ({ content: { content }, userId, createdAt }) => {
  return (
    <div>
      <div>Autor: {userId}</div>
      <div>Data: {createdAt}</div>
      <div>{content}</div>
    </div>
  );
};

const Typing = ({ typing }) => {
  const [currentTime, setCurrentTime] = useState(dayjs());
  useEffect(() => {
    const mainInterval = setInterval(() => {
      setCurrentTime(dayjs());
    }, 1000);
    return () => {
      clearInterval(mainInterval);
    };
  }, []);
  const currentlyTyping = Object.keys(typing).filter((key) =>
    dayjs(typing[key]).add(3, "second").isAfter(currentTime)
  );
  console.log("[typing]", typing);
  if (!currentlyTyping.length) return null;
  return <>{currentlyTyping.join(", ")} typing...</>;
};

// const Room = ({ roomId }) => {
//   const {
//     isLoaded,
//     messages,
//     reads,
//     typing,
//     sendTyping,
//     sendMessage,
//     scrollContainerRef,
//     scrollFunction,
//   } = useRoom(roomId);
//   if (!isLoaded) return null;
//   return (
//     <div>
//       <div
//         style={{
//           position: "relative",
//           width: 300,
//           height: 500,
//           border: "1px solid black",
//           overflowY: "scroll",
//           display: "flex",
//           flexDirection: "column-reverse",
//         }}
//         ref={scrollContainerRef}
//         onScroll={scrollFunction}
//       >
//         <Typing typing={typing} />
//         {messages.map((message) => (
//           <Message {...message} />
//         ))}
//       </div>
//       <button
//         onClick={() => {
//           sendMessage(
//             "qweasdjkmnas asjkdmnasiodas asjmndijokasmdjas asjdijoasd"
//           );
//         }}
//       >
//         send
//       </button>
//       <input
//         onChange={() => {
//           sendTyping();
//         }}
//       />
//     </div>
//   );
// };
