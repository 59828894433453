import { List } from "./services/List";

const AuthorQuery = `
query AuthorQuery($from: Int!, $search: String!) {
  records: authors(skip: $from, take: 10, where: {
    fname: {
      contains: $search
    }
  }) {
    id
    fname
  }
  aggregation: aggregateAuthor(where: {
    fname: {
      contains: $search
    }
  }) {
    _count {
      _all
    }
  }
}
`;

export default () => {
  return (
    <List
      mainKey={"fname"}
      query={AuthorQuery}
      path="/authors"
      name="Autorzy"
      plural="autorów"
    />
  );
};
