import {
  Input,
  Select,
  Submit,
  Textarea,
  TagSelect,
  DateSelect,
} from "./services/components";
import dayjs from "dayjs";
import { useEffect, useState, useRef } from "react";
import { useMutation, useQuery } from "urql";
import { useAtom } from "jotai";
import {
  tagsAtom,
  authorsAtom,
  userAtom,
  reklamodawcyAtom,
  usersAtom,
} from "./services/atoms";
import { pipe } from "ramda";
import Editor from "./services/Editor";
import { useGQLForm } from "./services/hooks";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import { useWatch, useFormState } from "react-hook-form";
import { useHistory, useParams, Prompt, Link } from "react-router-dom";
import { CreateArticle, startingData } from "./Articles";
import { Helmet } from "react-helmet";
import { Save, File, BookOpen, Monitor, Copy, Database } from "react-feather";
import ky from "ky";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const DataQuery = `
query ($id: Int!) {
    article(where: {
        id: $id
    }) {
      id
      title
      excerpt
      status
      slug
      fb_image
      inside_image
      croppoints
      speciallist
      htmlContent
      social_title
      first_publish
      comments
      license
      published_at
      reklamodawca
      created_by
      auto_ads
      auto_codes
      authors {
          user_id
      }
      tags {
          tag_id
      }
    }
  }
`;

const CreateQuery = `
mutation CreateArticle($createData: ArticleCreateInput!) {
    createArticle(data: $createData) {
      id
    }
  }
`;

const UpdateQuery = `
mutation UpdateArticle($updateData: ArticleUpdateInput!, $id: Int!) {
    updateArticle(data: $updateData, where: {
        id: $id
    }) {
      id
    }
  }
`;

const Crop = ({
  src,
  cropPoints,
  onChange
}) => {
  const [dataurl, setDataurl] = useState('')
  const cropperRef = useRef(null);
  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    onChange(cropper.getData());
    if (cropper && cropper.getCroppedCanvas())
      setDataurl(
        cropperRef.current.cropper.getCroppedCanvas().toDataURL()
      )
  };

  const data = cropPoints || null

  return <>
  <Cropper
src={src}
style={{ height: 400, width: "100%" }}
crop={onCrop}
ref={cropperRef}
data={data}
autoCrop={true}
autoCropArea={1}
viewMode={1}

/>
<div style={{
  width: 900,
  height: 600
}}>
  <img style={{
    objectFit: "cover",
    height: 600
  }} src={dataurl} />
</div>
</>
}

// 'https://histmag.org/grafika/2023_news_i_redakcyjne/ek/Polityka/kopernik.jpg'
// 'https://images.pexels.com/photos/14198757/pexels-photo-14198757.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
const Snippets = () => {
  const [showed, setShowed] = useState(false);

  const [{ data }, _] = useQuery({
    query: `{
      snippets(where: {
        type: {
          equals: "snippet"
        }
      }) {
        id
        name
      }
    }`,
  });
  if (!data) return null;
  return (
    <div
      className="absolute right-0"
      style={{
        top: -16,
        zIndex: 1000,
      }}
    >
      <div
        onClick={() => {
          setShowed((a) => !a);
        }}
      >
        SNIPPETY
      </div>
      {showed && (
        <div className="w-96 overflow-scroll pl-4 bg-white">
          <table>
            {data.snippets.map((snippet) => (
              <tr>
                <td>{snippet.id}</td>
                <td>{snippet.name}</td>
              </tr>
            ))}
          </table>
        </div>
      )}
    </div>
  );
};

const slugify = (str) =>
  !str
    ? ""
    : str
        .replace(/[ęĘóÓąĄśŚłŁżŻźŹćĆńŃ]/g, (match) => {
          const replacement = {
            ę: "e",
            Ę: "E",
            ó: "o",
            Ó: "O",
            ą: "a",
            Ą: "A",
            ś: "s",
            Ś: "S",
            ł: "l",
            Ł: "L",
            ż: "z",
            Ż: "Z",
            ź: "z",
            Ź: "Z",
            ć: "c",
            Ć: "C",
            ń: "n",
            Ń: "N",
          };
          return replacement[match];
        })
        .replace(/[^A-Za-z0-9\s-]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-");

export const Chars = ({ control, defaultValue, field }) => {
  const title = useWatch({
    control,
    name: field,
    defaultValue,
  });
  if (!title) return null;
  return <span>{title.length} znaków</span>;
};

export const SlugWarning = ({ control, titleValue, slugValue, setValue }) => {
  const title = useWatch({
    control,
    name: "title",
    defaultValue: titleValue,
  });
  const slug = useWatch({
    control,
    name: "slug",
    defaultValue: slugValue,
  });
  return slugify(title) === slug ? null : (
    <div className="border-2 border-red-800 p-8">
      SLUG JEST RÓŻNY OD AUTOMATYCZNIE WYGENEROWANEGO{" "}
      <span onClick={() => setValue("slug", slugify(title))}>USTAW</span>
    </div>
  );
};

export const CharsContent = ({ control, defaultValue, editor }) => {
  const [number, setNumber] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setNumber((e) => e + 1);
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getPartialCaret = () => {
    if (editor && editor.blocks) {
      let currentBlockIndex = editor.blocks.getCurrentBlockIndex();
      let selection = document.getSelection();

      if (!selection) return [0, 0];

      let cursorPosition = selection.anchorOffset;

      let parent = selection.anchorNode;
      if (!parent || !cursorPosition) return [0, 0];
      // console.log("[parent]", parent);
      while (
        !parent.classList ||
        !(
          parent.classList.contains("ce-block__content") ||
          parent.classList.contains("body")
        )
      ) {
        // console.log("[parent]", parent);
        parent = parent.parentNode;
        if (!parent) return [0, 0];
      }

      const getRealCaret = () => {
        if (!parent.classList.contains("ce-block__content")) return 0;
        const parentText = parent.innerText;
        const selectionText =
          selection.anchorNode.innerText || selection.anchorNode.data;
        // console.log("[parentText]", parentText);
        // console.log("[selectionText]", selectionText);
        const selectionInParentIndex = parentText.indexOf(selectionText);
        return selectionInParentIndex;
      };

      // parent.id = "mark" + number;
      const caretRealPosition = getRealCaret();

      // console.log("[currentBlockIndex]", currentBlockIndex);
      // console.log("[cursorPosition]", cursorPosition);
      // console.log("[caretRealPosition]", caretRealPosition);
      return [currentBlockIndex, cursorPosition + caretRealPosition];
    }

    return [0, 0];
  };

  const [blockIndex, cursorIndex] = getPartialCaret();
  const htmlContent = useWatch({
    control,
    name: "htmlContent",
    defaultValue,
  });

  const extractText = (block) => {
    if (["paragraph", "header", "quote"].includes(block.type))
      return block.data.text;
    if (block.type === "list") return block.data.items.join("");
    if (block.type === "raw") return block.data.html;
    if (block.type === "oldCode") return block.data.content;
  };

  const eraseHtml = (data) => data.replace(/<\/?[a-zA-Z0-9>]+>/g, "");

  const countChars = (data) => data.length;

  const sanitizeAndCount = pipe(extractText, eraseHtml, countChars);

  const sumOfCharacters = (index) =>
    JSON.parse(htmlContent)
      .slice(0, index)
      .filter((block) =>
        ["paragraph", "header", "quote", "list", "raw", "oldCode"].includes(
          block.type
        )
      )
      .map(sanitizeAndCount)
      .reduce((agg, next) => agg + next, 0);

  if (!htmlContent) return null;
  return (
    <span>
      {sumOfCharacters(blockIndex) + cursorIndex}
      {"/"}
      {sumOfCharacters(1000000)} znaków
    </span>
  );
};

const BookPopup = ({ close, content, setContent }) => {
  return (
    <div
      className="flex fixed bg-white border-2 z-50 overflow-y-auto p-8"
      style={{
        top: 200,
        left: 200,
        bottom: 200,
        right: 200,
      }}
    >
      <div
        className="fixed"
        style={{
          right: 180,
          top: 180,
          width: 40,
          height: 40,
          background: "white",
          cursor: "pointer",
        }}
        onClick={close}
      >
        X
      </div>
      <div className="w-full pr-4">
        <label className="block text-sm font-medium mb-2" for="">
          Edytor właściwości
        </label>
        {content.map((row, ind) => {
          return (
            <div className="flex py-2">
              <input
                className="border-2 w-1/2 p-2"
                value={row[0]}
                onChange={(e) => {
                  setContent([
                    ...content.slice(0, ind),
                    [e.target.value, row[1]],
                    ...content.slice(ind + 1),
                  ]);
                }}
              />
              <input
                className="border-2 w-1/2 mx-2 p-2"
                value={row[1]}
                onChange={(e) => {
                  setContent([
                    ...content.slice(0, ind),
                    [row[0], e.target.value],
                    ...content.slice(ind + 1),
                  ]);
                }}
              />
              <div
                className="w-8 cursor-pointer px-2"
                onClick={() => {
                  setContent([
                    ...content.slice(0, ind),
                    ...content.slice(ind + 1),
                  ]);
                }}
              >
                usuń
              </div>
            </div>
          );
        })}
        <select
          className="border-2"
          value={false}
          onChange={(e) =>
            setContent([
              ...content,
              [
                e.target.value,
                e.target.value === "links" ? "LINK@@CENA@@FORMAT" : "",
              ],
            ])
          }
        >
          <option value={false}>dodaj nową właściwość</option>
          <option value={""}>inne</option>

          <option value="toCart">Do koszyka</option>
          <option value="links">Link zewnętrzny</option>
          <option value="coverImg">Okładka(obrazek)</option>

          <option value="author">Autor</option>
          <option value="title">Tytuł</option>
          <option value="rate">Ocena</option>
          <option value="price">Cena</option>
          <option value="originaltitle">Tytuł oryginalny</option>

          <option value="publisher">Wydawca</option>
          <option value="translation">Tłumaczenie</option>
          <option value="year">Rok wydania</option>
          <option value="cover">Oprawa</option>
          <option value="pages">Liczba stron</option>
          <option value="illustrations">Ilustracje</option>
          <option value="series">Seria</option>
          <option value="published">Data i miejsce wydania</option>
          <option value="premiere">Premiera</option>
          <option value="format">Format</option>
          <option value="lang">Język oryginalny</option>
          <option value="montage">Redakcja</option>
          <option value="ebookformat">Format ebooków</option>
          <option value="security">Zabezpieczenie ebooków</option>
          <option value="genre">Gatunek</option>
          <option value="isbn">ISBN</option>
          <option value="ean">EAN</option>
          <option value="englishtitle">Tytuł angielski</option>
          <option value="director">Reżyseria</option>
          <option value="screenplay">Scenariusz</option>
          <option value="cast">Obsada</option>
          <option value="productioncountry">Kraj produkcji</option>
          <option value="production">Produkcja</option>
          <option value="distribution">Dystrybucja</option>
          <option value="time">Czas</option>
        </select>
      </div>
      <div className="w-full relative">
        <label className="block text-sm font-medium mb-2" for="">
          Kod do skopiowania (lub wkopiowania)
        </label>
        <textarea
          className="absolute inset-0 w-full h-full"
          style={{
            top: 50,
          }}
          value={JSON.stringify(content)}
          onChange={(e) => setContent(JSON.parse(e.target.value))}
        />
      </div>
    </div>
  );
};

const CopyCharButton = ({ char, mark }) => {
  return (
    <button
      className="p-4 w-full border-2"
      onClick={(e) => {
        e.preventDefault();
        const el = document.createElement("textarea");
        el.value = char;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        Toastify({
          text: "Skopiowano do schowka",
          gravity: "bottom",
        }).showToast();
      }}
    >
      {mark || char}
    </button>
  );
};

const block = (event) => {
  event.preventDefault();
  event.returnValue = "";
};

export default () => {
  const [editor, setEditor] = useState(false);

  const [user] = useAtom(userAtom);
  const [users] = useAtom(usersAtom);
  const [tags] = useAtom(tagsAtom);
  const [authors] = useAtom(authorsAtom);
  const [reklamodawcy] = useAtom(reklamodawcyAtom);

  const [bookPopupActive, setBookPopupActive] = useState(false);
  const [bookPopupActiveContent, setBookPopupActiveContent] = useState([]);

  const [activeTags, setActiveTags] = useState(false);
  const [activeAuthors, setActiveAuthors] = useState(false);
  const [editorContent, setEditorContent] = useState(false);

  const [otherDirty, setOtherDirty] = useState(false);

  const { id: idArticle } = useParams();

  console.log("[users]", users);

  const parseData = (data) => ({
    ...data,
    croppoints: {
      unset: true,
      value: data.croppoints || '{}',
    },
    tags: {
      unset: true,
      value: {
        deleteMany: {
          article_id: { equals: parseInt(idArticle) },
          tag_id: { notIn: activeTags },
        },
        createMany: {
          data: activeTags.map((tag_id) => ({
            tag_id: tag_id,
          })),
          skipDuplicates: true,
        },
      },
    },
    authors: {
      unset: true,
      value: {
        deleteMany: {
          article_id: { equals: parseInt(idArticle) },
          user_id: { notIn: activeAuthors },
        },
        createMany: {
          data: activeAuthors.map((author_id) => ({
            user_id: author_id,
          })),
          skipDuplicates: true,
        },
      },
    },
    ...(editorContent ? { htmlContent: editorContent } : {}),
    comments: parseInt(data.comments),
    fb_image: data.fb_image.replace(
      "https://fs.new.histmag.org/view",
      "https://histmag.org"
    ),
    inside_image: data.inside_image.replace(
      "https://fs.new.histmag.org/view",
      "https://histmag.org"
    ),
    created_by: parseInt(data.created_by) || 0,
    auto_ads: parseInt(data.auto_ads),
  });

  const {
    register,
    setValue,
    handleSubmit,
    onSubmit,
    error,
    data,
    idExists,
    id,
    getValues,
    control,
  } = useGQLForm({
    DataQuery,
    CreateQuery,
    UpdateQuery,
    parseData,
    path: "articles",
    createKey: "createArticle",
    afterSubmit: () => {
      setOtherDirty(false);
      Toastify({
        text: "Zapisano",
        gravity: "bottom",
      }).showToast();
    },
  });

  // height: calc(100vh - 96px);
  // overflow-y: auto;
  // width: 30rem;

  const [res1, createMutation] = useMutation(CreateArticle);

  const { isDirty } = useFormState({ control });
  console.log("[otherDirty]", otherDirty);
  console.log("[isDirty]", isDirty);

  useEffect(() => {
    if (isDirty || otherDirty) {
      window.addEventListener("beforeunload", block);
    } else {
      window.removeEventListener("beforeunload", block);
    }

    return () => {
      window.removeEventListener("beforeunload", block);
    };
  }, [isDirty, otherDirty]);

  const getData = (key, def) => (idExists ? data.article[key] : def);

  useEffect(() => {
    if (!data && idExists) return () => {};

    register("published_at", {
      value: getData("published_at", ""),
    });

    register("first_publish", {
      value: getData("first_publish", ""),
    });

    if (!idExists) return () => {};
    setActiveTags(data.article.tags.map((el) => el.tag_id));
    setActiveAuthors(data.article.authors.map((el) => el.user_id));
  }, [data]);

  if (error || (!data && idExists)) return <div>Loading...</div>;


  const croppoints = getData("croppoints", {}) || {}
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Helmet>
        <title>{getValues("title") + "- Histmag CMS"}</title>
      </Helmet>
      <Prompt
        when={isDirty || otherDirty}
        message={() => "Pamiętaj o zapisie!"}
      />
      {bookPopupActive && (
        <BookPopup
          close={() => setBookPopupActive(false)}
          content={bookPopupActiveContent}
          setContent={setBookPopupActiveContent}
        />
      )}
      <div className="flex flex-col lg:flex-row">
        <div className="w-full p-4 fullsizescroll">



          <Input
            label={
              <span>
                {" "}
                Tytuł (
                <Chars
                  field="title"
                  control={control}
                  defaultValue={getData("title", "")}
                />
                )
              </span>
            }
            placeholder="Wpisz tytuł"
            {...register("title")}
            defaultValue={getData("title", "")}
            onBlur={() => {
              const conf = !getValues("slug");
              if (conf) {
                setValue("slug", slugify(getValues("title")));
              }
            }}
          />
          <SlugWarning
            control={control}
            titleValue={getData("title", "")}
            slugValue={getData("slug", "")}
            setValue={setValue}
          />
          <Textarea
            label={
              <span>
                {" "}
                Lead (
                <Chars
                  field="excerpt"
                  control={control}
                  defaultValue={getData("excerpt", "")}
                />
                )
              </span>
            }
            placeholder="Lead"
            {...register("excerpt")}
            defaultValue={getData("excerpt", "")}
          />

          

          <div className="z-0 relative">
            <label className="block text-sm font-medium mb-2" for="">
              Treść (
              <CharsContent
                control={control}
                defaultValue={getData("htmlContent", "[]")}
                editor={editor}
              />
              )
            </label>
            <div
              className="flex items-stretch"
              style={{
                minHeight: "800px",
              }}
            >
              <div className="w-full relative">
                <Editor
                  onChange={(value) => {
                    console.log("[chuj editor]");
                    setOtherDirty(true);
                    setEditorContent(value);
                  }}
                  htmlContent={getData("htmlContent", "[]")}
                  getEditor={(editor) => setEditor(editor)}
                />
              </div>
              <Snippets />
            </div>
          </div>

        <div>

{/*  */}
{getData("fb_image", "") && <>
<div>SOCIAL IMAGE</div>
<Crop onChange={data => {
  const newCroppoints = {
    ...getValues("croppoints"),
    fb_image: data
  }
  setValue("croppoints", newCroppoints)
}} src={getData("fb_image", "")} cropPoints={croppoints.fb_image} />
</>}

{getData("inside_image", "") && <>
<div>INSIDE IMAGE</div>
<Crop onChange={data => {
  const newCroppoints = {
    ...getValues("croppoints"),
    inside_image: data
  }
  setValue("croppoints", newCroppoints)
}} src={getData("inside_image", "")} cropPoints={croppoints.inside_image} />
</>}


</div>
        </div>
        <div
          className="p-4 fullsizescroll"
          style={{
            width: "30rem",
          }}
        >
          <div className="py-4">
            {getValues("status") === "published" ? (
              <>
                {dayjs
                  .tz(getValues("published_at"), "Europe/Warsaw")
                  .isBefore(dayjs()) ? (
                  <>
                    Tekst jest <strong>publiczny</strong>
                  </>
                ) : (
                  <>
                    Tekst <strong>czeka na publikację</strong>
                  </>
                )}
              </>
            ) : (
              <>
                Tekst jest <strong>niepubliczny</strong>
              </>
            )}
          </div>
          <div className="flex mb-2">
            <button
              className="p-4 w-full border-2 border-grey-800 bg-green-700 text-white inline-block text-center"
              type="submit"
            >
              Zapisz
            </button>
            <a
              href="https://fs.new.histmag.org/login?url=https://nc.new.histmag.org/remote.php/dav/files/mswigon&username=mswigon&password=2bTMgjX7TeV$2ED$&type=webdav&path=/histmag"
              target="_blank"
              className="p-4 w-full border-2 border-grey-800 bg-blue-700 text-white w-full inline-block text-center"
            >
              Pliki
            </a>
            <div
              className="p-4 w-full border-2 border-grey-800 cursor-pointer inline-block text-center"
              onClick={() => setBookPopupActive(true)}
            >
              Książka
            </div>
          </div>
          <div className="flex mb-2">
            {idExists && (
              <a
                className="border-2 border-grey-800 p-4 w-full cursor-pointer inline-block text-center"
                target="_blank"
                href={`https://histmag.org/${getValues("slug")}-${id}`}
              >
                Podgląd
              </a>
            )}
            {idExists && (
              <div
                className="border-2 border-grey-800 p-4 w-full cursor-pointer inline-block text-center"
                onClick={() => {
                  createMutation(startingData(user.id)).then((data) => {
                    const id = data.data.createArticle.id;
                    window.idReplaced = id;
                    window.additionalData = {
                      created_by: user.id,
                    };
                    handleSubmit(onSubmit)({ e: {} });

                    setTimeout(() => {
                      window.location.href = `/articles/${data.data.createArticle.id}`;
                    }, 4000);
                  });
                }}
              >
                Duplikuj
              </div>
            )}
            {idExists && (
              <Link
                className="border-2 border-grey-800 p-4 w-full cursor-pointer w-full inline-block text-center"
                to={`/metas/${id}`}
              >
                Meta
              </Link>
            )}
          </div>
          <div className="flex mb-2">
            <CopyCharButton char="—" />
            <CopyCharButton char="–" />
            <CopyCharButton char="„" />
            <CopyCharButton char="”" />
            <CopyCharButton char="*" mark="B" />
            <CopyCharButton char="_" mark="I" />
            <CopyCharButton char=" " mark="nbs" />
          </div>
          <Input
            label="Obrazek społecznościowy"
            placeholder="Link..."
            {...register("fb_image")}
            defaultValue={getData("fb_image", "")}
          />
          <Input
            label="Obrazek serwisowy"
            placeholder="Link..."
            {...register("inside_image")}
            defaultValue={getData("inside_image", "")}
          />
          <Select
            label="Status"
            {...register("status")}
            defaultValue={getData("status", "")}
            options={[
              "draft",
              "finished",
              "accepted",
              "published",
              "deleted",
              "deletedpermanently",
            ]}
          />
          <label className="block text-sm font-medium mb-2" for="">
            Pierwsza publikacja
          </label>
          <DateSelect
            onChange={(value) => setValue("first_publish", value)}
            defaultValue={getData("first_publish", dayjs().toISOString())}
          />
          <label className="block text-sm font-medium mb-2" for="">
            Opublikowano
          </label>
          <DateSelect
            onChange={(value) => setValue("published_at", value)}
            defaultValue={getData("published_at", dayjs().toISOString())}
          />
          <Select
            label="Licencja"
            {...register("license")}
            defaultValue={getData("license", "")}
            options={[
              ["cc-by-sa-30", "Wolna licencja"],
              ["copy", "Prawa zastrzeżone"],
            ]}
          />
          <Input
            label="Reklamodawca"
            placeholder="Reklamodawca"
            {...register("reklamodawca")}
            defaultValue={getData("reklamodawca", "")}
          />
          <select
            style={{
              position: "relative",
              top: -19,
              width: "100%",
              border: "1px solid rgba(0,0,0,0.1)",
            }}
            placeholder="Wybierz reklamodawcę..."
            onChange={(e) => setValue("reklamodawca", e.target.value)}
            value={false}
          >
            <option value={false}>Wybierz reklamodawcę</option>
            {reklamodawcy.map((reklamodawca) => (
              <option value={reklamodawca.reklamodawca}>
                {reklamodawca.reklamodawca}
              </option>
            ))}
          </select>
          <Select
            label="Redaktor"
            {...register("created_by")}
            defaultValue={getData("created_by", "")}
            options={[
              ...users.map((user) => [user.id, user.name]),
              [0, "nie wiadomo"],
            ]}
          />
          {idExists &&
            activeTags &&
            !tags
              .filter((tag) => activeTags.includes(tag.id))
              .some((tag) => tag.genre === "Rodzaj") && (
              <div className="p-4 bg-red-500 text-white mb-4">
                Powinien być chociaz jeden tag rodzajowy!!!!
              </div>
            )}
          {idExists && activeAuthors && activeTags && (
            <>
              <label className="block text-sm font-medium mb-2" for="">
                Tagi
              </label>
              <TagSelect
                options={tags.map((tag) => ({
                  value: tag.id,
                  label: tag.name,
                }))}
                onChange={(value) => {
                  setActiveTags(value);
                  setOtherDirty(true);
                }}
                defaultValue={activeTags}
              />

              {idExists && activeAuthors && !activeAuthors.length && (
                <div className="p-4 bg-red-500 text-white mb-4 mt-4">
                  MUSI BYĆ AUTOR!!!!
                </div>
              )}
              <label className="block text-sm font-medium mb-2 mt-2" for="">
                Autorzy
              </label>
              <TagSelect
                options={authors.map((tag) => ({
                  value: tag.id,
                  label: tag.fname,
                }))}
                onChange={(value) => {
                  setActiveAuthors(value);
                  setOtherDirty(true);
                }}
                defaultValue={activeAuthors}
              />
            </>
          )}

          <Input
            label="Tytuł social"
            placeholder="Wpisz tytuł social"
            {...register("social_title")}
            defaultValue={getData("social_title", "")}
            id="social_title"
          />

          <Input
            label="Slug"
            placeholder="Wpisz slug"
            {...register("slug")}
            defaultValue={getData("slug", "")}
            id="slug"
          />
          <Input
            label="Komentarze dozwolone"
            placeholder="0 lub 1"
            {...register("comments")}
            defaultValue={getData("comments", "1")}
          />
          <Input
            label="Auto reklamy"
            placeholder="0 lub 1"
            {...register("auto_ads")}
            defaultValue={getData("auto_ads", "1")}
          />
          <Input
            label="Auto kody"
            placeholder="tablica..."
            {...register("auto_codes")}
            defaultValue={getData("auto_codes", "[]")}
          />
        </div>
      </div>
    </form>
  );
};
