import React, { useState } from "react";
import { set, lensPath, clone } from "ramda";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useMutation, useQuery } from "urql";

function App() {
  const [template, setTemplate] = useState("");
  const [date, setDate] = useState("");
  const [articles, setArticles] = useState([]);

  const [data] = useQuery({
    query: ` {
    newsletters(take: 1, orderBy: {id: desc}) {
      id
    }
  }
`,
  });

  const [res, addNewsletter] = useMutation(`
  mutation ($content: String!){
    createNewsletter(data: {
      content: $content
    }) {
      id
    }
  }
  `);

  const changeKey = (ind, key) => (value) => {
    console.log(value);
    articles[ind][key] = value;
    setArticles(set(lensPath([ind, key]), value, articles));
  };

  const convertLinks = (content, articleId) =>
    content.replace(/"[^"]+":http\S+/g, (all) => {
      const text = all.split(":")[0].slice(1, -1);
      const link = all.split(":").slice(1).join(":");
      return `<a href="${link}?utm_source=Histmag.org&utm_medium=article-${articleId}">${text}</a>`;
    });

  const generate = () => {
    const template = `<html>

    <head>
      <meta charset="utf-8">
    </head>
    
    <body style="font-size: 13px;margin: 0;">
      <div id="szpalta" style="width: 450px;margin: 0 auto;">
        <img src="https://histmag.org/css/logo.gif" style="width: 100px;margin-right: 50px;float:left;">
        <div id="right" style="font-size: 20px;font-variant: small-caps;padding: 40px 0px;height: 20px;width: 300px;text-align: center;float: right;">
          Newsletter serwisu Histmag.org
        </div>
        <div style="clear:both"></div>

        ${articles
          .map((article) => {
            const format = article.format || "full";

            if (format === "full")
              return `<div class="box full" style="width: 100%;padding: 10px 10px;margin: 5px 0px;box-sizing: border-box;">
          <a href="https://histmag.org/${article.slug}-${
                article.id
              }?utm_source=histmag&utm_medium=newsletter">
            <img src="https://images.histmag.org/430,fit/${
              article.fb_image ||
              "https://histmag.org/grafika/miniImages/446280083.jpg"
            }" style="width: 430px !important;margin: 10px 0px;clear: both;">
            <h3 style="font-size:14px;">${article.title}</h3>
          </a>
          <p>${convertLinks(article.excerpt, article.id)}</p>
        </div>`;

            if (format === "short")
              return `<div class="box short" style="width: 100%;padding: 10px 10px;margin: 5px 0px;box-sizing: border-box;">
        <a href="https://histmag.org/${article.slug}-${
                article.id
              }?utm_source=histmag&utm_medium=newsletter">
          <h3 style="font-size:14px;">${article.title}</h3>
        </a>
        <p>${convertLinks(article.excerpt, article.id)}</p>
      </div>`;

            if (format === "left")
              return `<div class="box left" style="width: 100%;padding: 10px 10px;margin: 5px 0px;box-sizing: border-box;">
      <a href="https://histmag.org/${article.slug}-${
                article.id
              }?utm_source=histmag&utm_medium=newsletter">
        <img src="https://images.histmag.org/150,fit/${
          article.fb_image ||
          "https://histmag.org/grafika/miniImages/446280083.jpg"
        }" style="width: 150px !important;margin: 0px 10px 0px 0px;float:left;">
        <h3 style="font-size:14px;">${article.title}</h3>
      </a>
      <p>${convertLinks(article.excerpt, article.id)}</p>
    </div>
    <div style="clear:both"></div>`;
          })
          .join("\n\n")}
    
        <hr> Życzę miłej lektury!
        <br>
        <br> Michał Świgoń
        <br> wydawca portalu „Histmag.org”
        <br>
        <a href="https://histmag.org">https://histmag.org</a>
        <br>
        <a href="mailto:m.swigon@histmag.org">m.swigon@histmag.org</a>
        <br>
        <a href="https://www.facebook.com/histmag.org">Facebook Histmag.org</a>
        <br> tel.: 0692929681
        <br>
        <br> PS. Jeśli z jakichś (smutnych dla mnie) powodów pragniesz usunąć się z bazy adresatów (składającej się wyłącznie z osób, które kiedykolwiek rejestrowały się na stronach "Histmag.org"), kliknij w ten link, a następnie wpisz adres, na który otrzymujesz
        newsletter i zaznacz opcję: Usuń z listy.
        <br>
    
      </div>
    </body>
    
    </html>
    `;
    setTemplate(template);
  };

  const search = () => {
    fetch(`https://service.histmag.org/newsletterRequest?date=${date}`)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setArticles(response);
      });
  };

  const swap = (index1, index2) => (arr) => {
    const result = Array.from(arr);
    const [removed] = result.splice(index1, 1);
    result.splice(index2, 0, removed);

    return result;

    console.log("indexes", index1, index2);
    let tempArr = clone(arr);
    const tempElement = clone(tempArr[index2]);
    tempArr[index2] = clone(tempArr[index1]);
    tempArr[index1] = tempElement;
    console.log("tempArr", tempArr);
    return tempArr;
  };

  const addToDatabase = () => {
    addNewsletter({
      content: template,
    });
  };

  console.log("articles", articles);
  console.log("data.newsletters", data);
  return (
    <>
      <div className="flex">
        <div className="w-full">
          {data.data && <div>Ostatnie id: {data.data.newsletters[0].id}</div>}
          <div>
            <input
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="border-2"
              placeholder="YYYY-MM-DD HH:mm:ss"
            />
            <br />
            <button onClick={search}>Search</button>
          </div>
          <div
            style={{
              width: "100%",
            }}
          >
            <DragDropContext
              onDragEnd={({ destination, source }) => {
                setArticles(swap(source.index, destination.index));
              }}
            >
              <Droppable droppableId={"MAIN"}>
                {(dropProvided, dropSnapshot) => (
                  <div ref={dropProvided.innerRef}>
                    {articles.map((article, index) => (
                      <Draggable
                        key={article.id}
                        draggableId={article.id.toString()}
                        index={index}
                      >
                        {(dragProvided, dragSnapshot) => (
                          <React.Fragment>
                            <div
                              {...dragProvided.draggableProps}
                              ref={dragProvided.innerRef}
                              style={{
                                background: index % 2 ? "gray" : "transparent",
                                display: "inline-block",
                                width: "100%",
                                ...dragProvided.draggableProps.style,
                              }}
                            >
                              <div {...dragProvided.dragHandleProps}>
                                {index + 1} - {article.id}
                                <img
                                  src={
                                    article.fb_image ||
                                    "https://histmag.org/grafika/miniImages/446280083.jpg"
                                  }
                                  style={{
                                    width: 50,
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  width: "50%",
                                  padding: 4,
                                  paddingTop: "24px",
                                  display: "inline-block",
                                }}
                              >
                                <input
                                  style={{
                                    width: "100%",
                                  }}
                                  value={article.title}
                                  onChange={(e) =>
                                    changeKey(index, "title")(e.target.value)
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  width: "50%",
                                  padding: 4,
                                  paddingTop: "24px",
                                  display: "inline-block",
                                }}
                              >
                                <input
                                  style={{
                                    width: "100%",
                                  }}
                                  onChange={(e) =>
                                    changeKey(index, "fb_image")(e.target.value)
                                  }
                                  value={article.fb_image}
                                />
                              </div>
                              <div
                                style={{
                                  width: "50%",
                                  padding: 4,

                                  paddingBottom: "24px",
                                  display: "inline-block",
                                }}
                              >
                                <textarea
                                  onChange={(e) =>
                                    changeKey(index, "excerpt")(e.target.value)
                                  }
                                  rows="6"
                                  style={{
                                    width: "100%",
                                  }}
                                  value={article.excerpt}
                                />
                              </div>
                              <div
                                style={{
                                  width: "50%",
                                  padding: 4,

                                  display: "inline-block",
                                  paddingBottom: "24px",
                                }}
                              >
                                <input
                                  style={{
                                    width: "100%",
                                  }}
                                  disabled
                                  value={`https://histmag.org/${article.slug}-${article.id}`}
                                />
                                <select
                                  style={{
                                    width: "100%",
                                    display: "inline-block",
                                  }}
                                  onChange={(e) =>
                                    changeKey(index, "format")(e.target.value)
                                  }
                                  value={article.format || "full"}
                                >
                                  <option value="full">Pełny</option>
                                  <option value="short">Krótki</option>
                                  <option value="left">Lewa strona</option>
                                  <option value="none">Brak</option>
                                </select>
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div onClick={generate}>GENERUJ</div>
          <div onClick={addToDatabase}>Dodaj do bazy</div>
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "100%",
            position: "relative",
          }}
        >
          <textarea
            style={{
              width: "90%",
              height: 560,
            }}
            value={template}
            onChange={(e) => setTemplate(e.target.value)}
          />
        </div>
        <div
          style={{
            width: "100%",
            border: "1px solid black",
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: template,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default App;
