import { List } from "./services/List";
import dayjs from "dayjs";
import { useMutation, useQuery } from "urql";
import { useAtom } from "jotai";
import { userAtom } from "./services/atoms";
import { Link } from "react-router-dom";

export default () => {
  const [user] = useAtom(userAtom);

  const IssuesQuery = `
query IssuesQuery {
  issues {
    name
    id
  }
  notifications(
    where: {
      user_id: {equals: ${user.id}},
      parent_type: {equals: "issues"}
    }
  ) {
    parent_id
  }
}
`;

  const [{ data }] = useQuery({ query: IssuesQuery });

  if (!data) return null;

  return (
    <div>
      {data.notifications.map((notification) => (
        <div>
          <Link to={"/issues/" + notification.parent_id}>
            {
              data.issues.find((issue) => issue.id === notification.parent_id)
                .name
            }
          </Link>
        </div>
      ))}
    </div>
  );
};
