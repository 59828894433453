import { List } from "./services/List";

const SnippetQuery = `
query SnippetQuery($from: Int!, $search: String!) {
  records: snippets(skip: $from, take: 10, where: {
    name: {
      contains: $search
    },
    type: {
        equals: "snippet"
    }
  }) {
    id
    name
  }
  aggregation: aggregateSnippet(where: {
    name: {
      contains: $search
    },
    type: {
        equals: "snippet"
    }
  }) {
    _count {
      _all
    }
  }
}
`;

export default () => {
  return (
    <List
      mainKey={"name"}
      query={SnippetQuery}
      path="/snippets"
      name="Snippety"
      plural="snipettów"
    />
  );
};
