import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { useQuery, useMutation } from "urql";
import {
  usersAtom,
  userAtom,
  currentTaskAtom,
  tasksAtom,
} from "../services/atoms";
import dayjs from "dayjs";
import { is } from "ramda";
import { useChatProvider } from "../ChatClient";

const niceFormat = (date) => {
  const dayDate = dayjs(date);
  if (dayDate.isSame(dayjs(), "hour"))
    return dayjs().diff(dayDate, "minute") + " minut temu";
  if (dayDate.isSame(dayjs(), "day")) return dayDate.format("HH:mm");
  if (dayDate.isSame(dayjs(), "year")) return dayDate.format("HH:mm, DD MMMM");
  return dayDate.format("HH:mm, DD MMMM YYYY");
};

const Message = ({ author, content, createdAt, isFirst }) => {
  if (content.content.content) return null;
  return (
    <div
      className={"flex items-start mb-4 text-sm " + (isFirst ? "mt-auto" : "")}
    >
      {/* <img
        src="https://twitter.com/adamwathan/profile_image"
        className="w-10 h-10 rounded mr-3"
      /> */}
      <div className="flex-1 overflow-hidden">
        <div>
          <span className="font-bold">{author.name} </span>
          <span className="text-grey text-xs">{niceFormat(createdAt)}</span>
          <span className="text-grey text-xs">
            {content.task_id ? ` (${content.task_id})` : ""}
          </span>
        </div>
        <p className="text-black leading-normal">{content.content}</p>
      </div>
    </div>
  );
};

// export const CHatBox = (props) => {
//   const [currentTask] = useAtom(currentTaskAtom);
//   return <ChatBoxInside

//   />
// }

export const ChatBox = ({ parent_id, parent_type, taskMode }) => {
  const [users] = useAtom(usersAtom);
  const [user] = useAtom(userAtom);
  const [currentTask, setCurrentTask] = useAtom(currentTaskAtom);

  const [messageText, setMessageText] = useState("");

  const {
    isLoaded,
    messages,
    reads,
    typing,
    sendTyping,
    sendMessage,
    scrollContainerRef,
    scrollFunction,
  } = useChatProvider({
    roomId: `${parent_type}----${parent_id}`,
    userId: user.id,
    currentTask,
  });

  const submitForm = () => {
    const taskObject =
      currentTask && taskMode
        ? {
            task_id: currentTask,
          }
        : {};
    setMessageText("");

    sendMessage({
      content: messageText,
      ...taskObject,
    });
  };

  // console.log("[isLoaded]", isLoaded);
  // console.log("[messages]", messages);
  // console.log("[users]", users);

  if (!isLoaded) return null;
  return (
    <>
      {taskMode && currentTask && (
        <div
          onClick={() => {
            setCurrentTask(false);
          }}
        >
          Czat dla zadania {currentTask}
        </div>
      )}
      <div
        style={{
          height: 405,
        }}
        className="px-6 py-4 flex-1 overflow-y-scroll flex flex-col"
        ref={scrollContainerRef}
        onScroll={scrollFunction}
      >
        {messages
          .map((message) => ({
            ...message,
            author: users.find((el) => el.id == message.userId),
          }))
          .map((message, ind) => (
            <Message isFirst={ind === 0} {...message} />
          ))}
      </div>
      <div className="pb-6 px-4 flex-none">
        <div className="flex rounded-lg border-2 border-grey overflow-hidden">
          <span className="text-3xl text-grey border-r-2 border-grey p-2"></span>
          <form
            onKeyDown={(e) => {
              if (e.ctrlKey && e.keyCode === 13) {
                submitForm();
              }
            }}
            className="w-full h-full"
            onSubmit={(e) => {
              e.preventDefault();
              submitForm();
            }}
          >
            <textarea
              className="w-full px-4 pt-2"
              placeholder="Wiadomość..."
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
            />
          </form>
        </div>
      </div>
    </>
  );
};
