import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import Login from "./Login";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter as Router } from "react-router-dom";
import { configure } from "react-hotkeys";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { useAtom } from "jotai";
import { windowWidthAtom } from "./services/atoms";

const WindowWidthTracker = () => {
  const [_, setWidth] = useAtom(windowWidthAtom);
  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);
  return null;
};

configure({
  ignoreTags: [],
});

// Auth0 Provider + Router
ReactDOM.render(
  <React.StrictMode>
    <WindowWidthTracker />
    <Auth0Provider
      domain="ks-auth.auth0.com"
      clientId="9YUoST6EltPnp6O5cwnWYFkPuXCnESSB"
      redirectUri={window.location.origin}
      audience={"https://api.histmag.org"}
      // scope={"all"}
    >
      <Router>
        <Login />
      </Router>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
