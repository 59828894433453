import { useQuery } from "urql";
import { useState } from "react";
import { Link } from "react-router-dom";
import { zipObj } from "ramda";

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export const List = ({
  query,
  path,
  mainKey,
  name = "Tagi",
  plural = "tagów",
  createAction = false,
  columns = () => null,
  pageSize = 10,
  filters = [],
}) => {
  console.log("createAction", createAction);
  const [currentPage, setCurrentPage] = useState(1);
  const [filtersValues, setFiltersValues] = useState({});

  const transformFilterValues = () => {
    const filterNames = filters.map((el) => el.field);
    const filterValues = filters.map((el) =>
      filtersValues[el.field] && filtersValues[el.field] !== "false"
        ? [el.parse(filtersValues[el.field])]
        : el.values.map((el) => el.value).filter((el) => el !== null)
    );
    return zipObj(filterNames, filterValues);
  };

  const [search, setSearch] = useState("");
  const [result] = useQuery({
    query: query,
    variables: {
      from: (currentPage - 1) * pageSize,
      search,
      ...transformFilterValues(),
    },
  });
  const { data, fetching, error } = result;

  if (!data) return <p>Loading...</p>;
  if (error) return <p>Oh no... {error.message}</p>;

  const allPages = Math.ceil(data.aggregation._count._all / pageSize);
  const pages = [
    1,
    2,
    3,
    currentPage - 1,
    currentPage,
    currentPage + 1,
    allPages - 2,
    allPages - 1,
    allPages,
  ]
    .filter(onlyUnique)
    .sort((a, b) => a - b)
    .filter((el) => el > 0 && el <= allPages);

  console.log("createAction", createAction);

  return (
    <>
      <section className="py-8 px-6">
        <div className="flex flex-wrap items-center">
          <div className="flex items-center mb-5 lg:mb-0">
            <div>
              <h2 className="mb-1 text-2xl font-bold">{name}</h2>
              <p className="text-sm text-gray-500 font-medium">
                <span className="text-indigo-500">
                  {data.aggregation._count._all}
                </span>
                <span> {plural}</span>
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-auto flex pr-4 mb-4 md:mb-0 md:mr-4 md:ml-auto border rounded bg-white">
            <input
              className="py-2 pl-4 pr-2 text-sm placeholder-gray-500"
              type="text"
              placeholder="Type to search..."
              value={search}
              onChange={(e) => {
                setCurrentPage(1);
                setSearch(e.target.value);
              }}
            />
            {filters.map((filter) => {
              return (
                <>
                  {filter.name}
                  <select
                    value={filtersValues[filter.field]}
                    onChange={(e) =>
                      setFiltersValues((filters) => ({
                        ...filters,
                        [filter.field]: e.target.value,
                      }))
                    }
                  >
                    <option value={false}>Wszyscy</option>
                    {filter.values.map((value) => (
                      <option value={value.value}>{value.label}</option>
                    ))}
                  </select>
                </>
              );
            })}
          </div>
          {createAction ? (
            <span
              className="px-4 py-2 cursor-pointer bg-gray-800 text-white"
              onClick={createAction}
            >
              Dodaj
            </span>
          ) : (
            <Link
              className="md:w-auto flex items-center py-2 px-4 mr-3 rounded bg-indigo-500 hover:bg-indigo-600 text-white text-sm font-medium"
              to={`${path}/create`}
            >
              <span className="inline-block mr-1">
                <svg
                  width="14"
                  height="14"
                  viewbox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.6667 0.333344H2.33337C1.20004 0.333344 0.333374 1.20001 0.333374 2.33334V11.6667C0.333374 12.8 1.20004 13.6667 2.33337 13.6667H11.6667C12.8 13.6667 13.6667 12.8 13.6667 11.6667V2.33334C13.6667 1.20001 12.8 0.333344 11.6667 0.333344ZM9.66671 7.66668H7.66671V9.66668C7.66671 10.0667 7.40004 10.3333 7.00004 10.3333C6.60004 10.3333 6.33337 10.0667 6.33337 9.66668V7.66668H4.33337C3.93337 7.66668 3.66671 7.40001 3.66671 7.00001C3.66671 6.60001 3.93337 6.33334 4.33337 6.33334H6.33337V4.33334C6.33337 3.93334 6.60004 3.66668 7.00004 3.66668C7.40004 3.66668 7.66671 3.93334 7.66671 4.33334V6.33334H9.66671C10.0667 6.33334 10.3334 6.60001 10.3334 7.00001C10.3334 7.40001 10.0667 7.66668 9.66671 7.66668Z"
                    fill="#8880EB"
                  ></path>
                </svg>
              </span>
              <span>Add</span>
            </Link>
          )}
        </div>
      </section>
      <section className="py-8">
        <div className="container px-4 mx-auto">
          {data.records.map((tag) => (
            <div className="p-10 mb-4 bg-white shadow rounded">
              <div className="flex flex-wrap items-between -mx-4 justify-between">
                <div className="w-full lg:w-3/12 flex px-4 mb-6 lg:mb-0">
                  <div>
                    <p className="text-sm font-medium">{tag[mainKey]}</p>
                  </div>
                </div>

                {columns(tag)}

                <div className="w-full lg:w-1/12 px-4">
                  <div className="flex items-center">
                    {name === 'Artykuły' ? <a
                      className="p-2 mr-3 bg-purple-100 rounded"
                      href={`${path}/${tag.id}`}
                      target="_blank"
                    >
                      Edytuj
                    </a> : <Link
                      className="p-2 mr-3 bg-purple-100 rounded"
                      to={`${path}/${tag.id}`}
                    >
                      Edytuj
                    </Link>}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="flex flex-wrap mt-2 -mx-4 items-center justify-between">
            <div className="w-full lg:w-auto px-4 flex items-center justify-center">
              {pages.map((page) => (
                <a
                  className={
                    (currentPage === page
                      ? "bg-gray-300 text-white"
                      : "bg-white hover:bg-indigo-50 text-gray-500") +
                    " inline-flex mr-3 items-center justify-center w-8 h-8 text-xs border border-gray-300 rounded"
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentPage(page);
                  }}
                >
                  {page}
                </a>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
