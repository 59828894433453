import { useAtom } from "jotai";
import { useEffect, useState, forwardRef } from "react";
import { Archive, FileText, MessageCircle, MessageSquare } from "react-feather";
import { useMutation, useQuery } from "urql";
import { useDebounce } from "../Issue";
import {
  currentTaskAtom,
  tasksAtom,
  usersAtom,
  tasksConfigAtom,
} from "../services/atoms";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ky from "ky";
import { useTaskProvider } from "../TaskClient";

const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
  <span onClick={onClick} ref={ref}>
    {value || "brak"}
  </span>
));

const StringField = ({ value, update }) => {
  const [changedValue, setChangedValue] = useState(false);
  return (
    <input
      className="w-full"
      value={changedValue || value}
      onChange={(e) => setChangedValue(e.target.value)}
      onBlur={() => {
        update(changedValue);
        setChangedValue(false);
      }}
    />
  );
};

const Task = ({
  columns,
  task,
  updateTask,
  deleteTask,
  findField,
  activateTask,
  chatAboutTask,
  currentlyChattingAbout,
}) => {
  const [users] = useAtom(usersAtom);
  console.log("[task]", task);
  // const [currentTask, setCurrentTask] = useAtom(currentTaskAtom);

  return (
    <div className="flex py-4 w-auto">
      {columns.map((column) => {
        const fieldName = column.field;
        const field = findField(fieldName);
        const value = task[fieldName];
        return (
          <div className={`${column.size}rem`}>
            {field.type === "string" && (
              <StringField
                value={value}
                update={(value) => {
                  updateTask(fieldName, value);
                }}
              />
            )}
            {field.type === "user" && (
              <select
                className="w-full"
                value={value}
                onChange={(e) => {
                  updateTask(fieldName, parseInt(e.target.value));
                }}
              >
                <option value={0}>Nikt</option>
                {users.map((user) => (
                  <option value={user.id}>{user.name}</option>
                ))}
              </select>
            )}
            {field.type === "date" && (
              <>
                <DatePicker
                  dateFormat={"yyyy-MM-dd"}
                  selected={value ? dayjs(value).toDate() : false}
                  onChange={(date) => {
                    updateTask(fieldName, dayjs(date).format("YYYY-MM-DD"));
                  }}
                  customInput={<ExampleCustomInput />}
                />
                {value && (
                  <span
                    onClick={() => {
                      updateTask(fieldName, null);
                    }}
                  >
                    d
                  </span>
                )}
              </>
            )}
          </div>
        );
      })}
      {/* <div className="w-full">
        <select
          className="w-full"
          value={changedStatus}
          onChange={(e) => {
            setChangedStatus(e.target.value);
            save({
              id: data.id,
              data: {
                status: { set: e.target.value },
              },
            });
          }}
        >
          <option value="">Do zrobienia</option>
          <option value="inprogress">W trakcie</option>
          <option value="done">Wykonane</option>
        </select>
      </div> */}

      <div className="w-32 flex justify-evenly">
        <div
          onClick={() => {
            deleteTask();
          }}
        >
          <Archive />
        </div>
        <div
          onClick={() => {
            activateTask();
          }}
        >
          <FileText />
        </div>
        <div onClick={chatAboutTask}>
          {currentlyChattingAbout ? <MessageSquare /> : <MessageCircle />}
        </div>
      </div>
    </div>
  );
};

const EtherpadPopup = ({ taskId, setTaskContentId }) => {
  return (
    <div className="absolute p-8 inset-0 bg-white border-2 z-50">
      <div onClick={() => setTaskContentId(false)}>close</div>
      <iframe
        src={`https://etherpad.dev.krzysztofsikorski.pl/p/task_${taskId}?showChat=false&showLineNumbers=false`}
        height={500}
        style={{
          width: "100%",
        }}
      ></iframe>
    </div>
  );
};

export const NewTasklist = ({ roomId }) => {
  const [taskContentId, setTaskContentId] = useState(false);
  const [currentTaskId, setCurrentTaskId] = useAtom(currentTaskAtom);
  const [config, setConfig] = useAtom(tasksConfigAtom);
  const { room, tasks, sendData } = useTaskProvider({ roomId });

  useEffect(() => {
    (async () => {
      const data = await ky
        .get("https://room.prod.krzysztofsikorski.pl/config")
        .json();
      setConfig(data);
    })();
  }, []);

  if (!config || !room) return null;

  const currentRoom = config.rooms[roomId];
  const currentType = config.types[currentRoom.type];
  console.log("[currentRoom]", currentRoom);
  console.log("[currentType]", currentType);
  const findField = (name) => currentType.fields[name];
  const fullRemWidth = currentRoom.columns.reduce((agg, nxt) => agg + nxt.size);

  console.log("[tasks]", tasks);
  const tasksArray = Object.keys(tasks).map((taskId) => ({
    _id: taskId,
    ...tasks[taskId],
  }));
  // TODO: sort

  return (
    <div className="w-full overflow-x-auto">
      <div
        style={{
          width: `${fullRemWidth}rem`,
        }}
      >
        <div className="flex">
          {currentRoom.columns.map((column) => (
            <div className={`${column.size}rem`}>{column.name}</div>
          ))}
          <div className="w-32">Akcje</div>
        </div>
        {tasksArray.map((task) => (
          <Task
            key={task._id}
            columns={currentRoom.columns}
            findField={findField}
            task={task}
            updateTask={(key, value) => {
              sendData({
                type: "updateRecord",
                data: {
                  _id: task._id,
                  key,
                  value,
                },
              });
            }}
            deleteTask={() => {
              sendData({
                type: "deleteRecord",
                data: task._id,
              });
            }}
            activateTask={() => setTaskContentId(task._id)}
            chatAboutTask={() => setCurrentTaskId(task._id)}
            currentlyChattingAbout={currentTaskId === task._id}
          />
        ))}
        {taskContentId && (
          <EtherpadPopup
            taskId={taskContentId}
            setTaskContentId={setTaskContentId}
          />
        )}
        <span
          onClick={() => {
            sendData({
              type: "createRecord",
            });
          }}
        >
          Nowe
        </span>
      </div>
    </div>
  );
};
