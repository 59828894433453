import dayjs from "dayjs";
import { lensPath, map, set, valuesIn } from "ramda";
import { useState } from "react";
import toastify from "toastify-js";
import { useQuery, useMutation } from "urql";
import { DateSelect, DateSelectPoland } from "./services/components";
import { uuid } from "uuidv4";

const Dictionaries = `{
  dictionaries {
    name
    value
  }
}`;

const UpdateDictionary = `mutation UpdateDictionary($name: String, $value: String) {
  updateDictionary(data: {
    value: {
      set: $value
    }
  }, where: {
    name: $name
  }) {
    name
  }
  }`;

const priorityNames = [
  "Główny",
  "Góra - lewy",
  "Góra - prawy",
  "Dół - lewy",
  "Dół - prawy",
];

export default () => {
  const [cash, setCash] = useState(false);
  const [note, setNote] = useState(false);
  const [shares, setShares] = useState(false);
  const [priorities, setPriorities] = useState(false);

  const [{ data, error }] = useQuery({
    query: Dictionaries,
  });

  const [_, updateDictionary] = useMutation(UpdateDictionary);

  if (!data) return <div>Ładowanie...</div>;

  const priorityDataFromServer = data.dictionaries.find(
    (el) => el.name === "priorities"
  );
  const priorityData = priorities || JSON.parse(priorityDataFromServer.value);

  const setPrioritiesPath = (path, content) =>
    setPriorities(set(lensPath(path), content, priorityData));
  const addPriorityIndex = (index) => {
    setPriorities({
      ...priorityData,
      [index]: [
        ...priorityData[index],
        { identifier: uuid(), article: "", date: dayjs().toISOString() },
      ],
    });
  };
  const removePriorityIndex = (index, subIndex) => {
    setPriorities({
      ...priorityData,
      [index]: priorityData[index].filter((el, ind) => ind !== subIndex),
    });
  };

  return (
    <div className="p-8">
      <button
        onClick={() => {
          updateDictionary({
            name: "priorities",
            value: JSON.stringify(priorityData),
          }).then(() => {
            toastify({
              text: "Zapisano",
              gravity: "bottom",
            }).showToast();
          });
        }}
        className="border-2 bg-red-300"
      >
        Save
      </button>
      <br />
      <div className="flex flex-wrap">
        {[0, 1, 2, 3, 4].map((place) => {
          return (
            <div className="w-full md:w-1/3 p-2">
              <h3 className="text-xl bg-black p-4 text-white">
                [prio] {priorityNames[place]}
              </h3>

              {priorityData[place].map((entry, ind) => (
                <div key={entry.identifier}>
                  <input
                    className="border-2"
                    type="text"
                    value={entry.article}
                    onChange={(e) =>
                      setPrioritiesPath([place, ind, "article"], e.target.value)
                    }
                  />{" "}
                  -{" "}
                  <DateSelectPoland
                    onChange={(value) =>
                      setPrioritiesPath([place, ind, "date"], value)
                    }
                    defaultValue={entry.date}
                  />
                  <span onClick={() => removePriorityIndex(place, ind)}>
                    remove
                  </span>
                </div>
              ))}
              <br />
              <button
                onClick={() => {
                  addPriorityIndex(place);
                }}
              >
                Nowy
              </button>
            </div>
          );
        })}

        <div className="w-full md:w-1/3 p-2">
          <h3 className="text-xl bg-black p-4 text-white">POPUP</h3>

          {priorityData["popup"].map((entry, ind) => (
            <div>
              <input
                className="border-2"
                type="text"
                value={entry.article}
                onChange={(e) =>
                  setPrioritiesPath(["popup", ind, "article"], e.target.value)
                }
              />{" "}
              -{" "}
              <DateSelectPoland
                onChange={(value) =>
                  setPrioritiesPath(["popup", ind, "date"], value)
                }
                defaultValue={entry.date}
              />
              <span onClick={() => removePriorityIndex("popup", ind)}>
                remove
              </span>
            </div>
          ))}
          <br />
          <button
            onClick={() => {
              addPriorityIndex("popup");
            }}
          >
            Nowy
          </button>
        </div>

        <div className="w-full md:w-1/3 p-2">
          Kasa:
          <input
            value={
              cash === false
                ? data.dictionaries.find((el) => el.name === "cash").value
                : cash
            }
            onChange={(e) => setCash(e.target.value)}
          />
          <button
            onClick={() => {
              updateDictionary({
                name: "cash",
                value: cash,
              }).then(() => {
                toastify({
                  text: "Zapisano",
                  gravity: "bottom",
                }).showToast();
              });
            }}
            className="border-2 bg-red-300"
          >
            Save
          </button>
          <br />
          <br />
          <br />
        </div>
        <div className="w-full md:w-1/3 p-2">
          notka:
          <textarea
            value={
              note === false
                ? data.dictionaries.find((el) => el.name === "note").value
                : note
            }
            onChange={(e) => setNote(e.target.value)}
          />
          <button
            onClick={() => {
              updateDictionary({
                name: "note",
                value: note,
              }).then(() => {
                toastify({
                  text: "Zapisano",
                  gravity: "bottom",
                }).showToast();
              });
            }}
            className="border-2 bg-red-300"
          >
            Save
          </button>
        </div>
        <div className="w-full md:w-1/3 p-2">
          shares(ankieta, push - radary, push - zostatniej chwili, artykuł):
          <textarea
            value={
              shares === false
                ? data.dictionaries.find((el) => el.name === "shares").value
                : shares
            }
            onChange={(e) => setShares(e.target.value)}
          />
          <button
            onClick={() => {
              updateDictionary({
                name: "shares",
                value: shares,
              }).then(() => {
                toastify({
                  text: "Zapisano",
                  gravity: "bottom",
                }).showToast();
              });
            }}
            className="border-2 bg-red-300"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
