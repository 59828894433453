import React from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import ReactSelect from "react-select";
import { is } from "ramda";

export const Input = React.forwardRef(
  ({ label, placeholder, changeIntercept = (q) => q, ...restOfProps }, ref) => (
    <div className="mb-6">
      <label className="block text-sm font-medium mb-2" for="">
        {label}
      </label>
      <input
        className="block w-full px-4 py-3 mb-2 text-md placeholder-gray-500 bg-white border rounded"
        type="text"
        name=""
        placeholder={placeholder}
        {...restOfProps}
        ref={ref}
      />
    </div>
  )
);

export const DateSelectPoland = ({ label, options, ...restOfProps }) => {
  console.log("[restOfProps]", restOfProps);
  return (
    <div className="border-2 inline-block">
      <Datetime
        className={
          "block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
        }
        dateFormat={"YYYY-MM-DD"}
        timeFormat={"HH:mm:ss"}
        initialValue={new Date(restOfProps.defaultValue)}
        onChange={(value) => {
          if (value.format) restOfProps.onChange(value.toISOString());
        }}
      />
    </div>
  );
};

export const DateSelect = ({ label, options, ...restOfProps }) => {
  console.log("[restOfProps]", restOfProps);
  return (
    <div className="border-2 inline-block">
      <Datetime
        className={
          "block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
        }
        dateFormat={"YYYY-MM-DD"}
        timeFormat={"HH:mm:ss"}
        utc
        initialValue={new Date(restOfProps.defaultValue)}
        onChange={(value) => {
          if (value.format) restOfProps.onChange(value.toISOString());
        }}
      />
    </div>
  );
};

export const TagSelect = ({ label, options, ...restOfProps }) => {
  console.log(restOfProps);
  return (
    <div>
      <ReactSelect
        isMulti
        onChange={(value) => restOfProps.onChange(value.map((el) => el.value))}
        defaultValue={restOfProps.defaultValue.map((el) =>
          options.find((option) => option.value == el)
        )}
        options={options}
      />
    </div>
  );
};

export const Select = React.forwardRef(
  ({ label, options, ...restOfProps }, ref) => (
    <div className="mb-6">
      <label className="block text-sm font-medium mb-2" for="">
        {label}
      </label>
      <div className="relative">
        <select
          ref={ref}
          className="appearance-none block w-full px-4 py-3 mb-2 text-sm placeholder-gray-500 bg-white border rounded"
          name="field-name"
          {...restOfProps}
        >
          {options.map((data) => {
            if (is(Array, data)) {
              const [option, label] = data;
              return <option value={option}>{label || option}</option>;
            } else {
              return <option value={data}>{data}</option>;
            }
          })}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-500">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewbox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
          </svg>
        </div>
      </div>
    </div>
  )
);

export const Textarea = React.forwardRef(
  ({ label, placeholder, ...restOfProps }, ref) => (
    <div className="mb-6">
      <label className="block text-sm font-medium mb-2" for="">
        {label}
      </label>
      <textarea
        ref={ref}
        style={{
          fontFamily: "inherit",
        }}
        className="block w-full px-4 py-3 mb-2 text-md placeholder-gray-500 bg-white border rounded text-black "
        name="field-name"
        rows="5"
        placeholder={placeholder}
        {...restOfProps}
      ></textarea>
    </div>
  )
);

export const Submit = () => (
  <button className="p-4 w-full bg-green-700 text-white" type="submit">
    Zapisz
  </button>
);
