import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "urql";
import dayjs from "dayjs";
import { map } from "ramda";

export const useGQLForm = ({
  CreateQuery,
  UpdateQuery,
  DataQuery,
  parseData,
  createKey,
  path,
  afterSubmit = () => {},
}) => {
  const { register, handleSubmit, setValue, getValues, control } = useForm();
  const history = useHistory();
  const { id } = useParams();
  const idExists = id !== "create";

  const [{ data, error }, _] = useQuery({
    query: DataQuery,
    variables: { id: parseInt(id) },
    pause: !idExists,
  });
  const [res1, createMutation] = useMutation(CreateQuery);
  const [res2, updateMutation] = useMutation(UpdateQuery);

  const onSubmit = (data) => {
    const idReplaced = window.idReplaced || id;
    const additionalData = window.additionalData || {};
    const statusDraftObject = window.idReplaced
      ? {
          status: "draft",
          published_at: dayjs().format("YYYY-MM-DDTHH:mm:ss") + ".000Z",
          first_publish: dayjs().format("YYYY-MM-DDTHH:mm:ss") + ".000Z",
          priority: 0,
          speciallist: "",
          social_title: "",
          ...additionalData,
        }
      : {};

    const parsedData = parseData({
      ...data,
      ...statusDraftObject,
    });
    console.log(parsedData);
    if (idExists) {
      afterSubmit();
      updateMutation({
        updateData: map(
          (obj) => (obj.unset ? obj.value : { set: obj }),
          parsedData
        ),
        id: parseInt(idReplaced),
      });
      setTimeout(() => {
        fetch(
          `https://histmag.org/api/revalidate?secret=asdnjksdnfuiaoenwu&path=/${data.slug}-${id}`
        );
      }, 1500);
    } else {
      createMutation({
        createData: parsedData,
      }).then((result) => {
        console.log(result);
        if (result.error) {
          console.error("Oh no!", result.error);
        } else {
          history.push(`/${path}/${result.data[createKey].id}`);
        }
      });
    }
  };

  return {
    register,
    handleSubmit,
    onSubmit,
    error,
    data,
    idExists,
    setValue,
    getValues,
    id,
    control,
  };
};
