import { atom } from "jotai";

export const tagsAtom = atom(false);
export const authorsAtom = atom(false);

export const usersAtom = atom(false);
export const userAtom = atom(false);
export const reklamodawcyAtom = atom(false);

export const chatHiddenAtom = atom(
  window.localStorage.getItem("chat_hidden")
    ? window.localStorage.getItem("chat_hidden") === "true"
    : false
);

export const tasksAtom = atom([]);
export const currentTaskAtom = atom(false);

export const windowWidthAtom = atom(false);
export const mobileModeAtom = atom((get) => get(windowWidthAtom) < 1100);

export const tasksConfigAtom = atom(false);
