import { useState } from "react";
export const AnalyticsReport = () => {
  const [reportText, setReportText] = useState("{}");
  const data = JSON.parse(reportText);

  return (
    <div>
      <textarea
        value={reportText}
        onChange={(e) => setReportText(e.target.value)}
      />

      {data.thisYearUsers && (
        <div
          style={{
            width: 600,
            margin: "0 auto",
          }}
        >
          <p>
            Statystyki tygodnia {data.start} - {data.end}:
          </p>
          <p>
            Wyniki: {Math.round(data.thisYearUsers / 10) / 100} tys. Czytelników
            ({Math.round(data.usersDifference * 100)}%{" "}
            {data.usersDifference > 0 ? "więcej" : "mniej"} niż rok temu),{" "}
            {Math.round(data.thisYearViews / 10) / 100} tys. odsłon (
            {Math.round(data.viewsDifference * 100)}%{" "}
            {data.viewsDifference > 0 ? "więcej" : "mniej"} niż rok temu).
          </p>
          <p>5 najpopularniejszych tekstów w minionym tygodniu:</p>

          <ul>
            {data.texts.map((text) => {
              return (
                <li>
                  {text.title} - {Math.round(text.users / 10) / 100} tys. red.
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
