import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Menu from "./Menu";
import Tags from "./Tags";
import Newsletter from "./Newsletter";
import Tag from "./Tag";
import { createClient, Provider, useMutation, useQuery } from "urql";
import Snippet from "./Snippet";
import Articles from "./Articles";
import Article from "./Article";
import ArticleMagic from "./ArticleMagic";
import Comments from "./Comments";
import Polls from "./Polls";
import Poll from "./Poll";
import Authors from "./Authors";
import Author from "./Author";
import Issues from "./Issues";
import Issue from "./Issue";
import Dictionary from "./Dictionary";
import Snippets from "./Snippets";
import { getEnv } from "./services/env";
import Moderated from "./Moderated";
import Contact from "./Contact";
import {
  usersAtom,
  userAtom,
  tagsAtom,
  authorsAtom,
  chatHiddenAtom,
  windowWidthAtom,
  reklamodawcyAtom,
} from "./services/atoms";
import { useAtom } from "jotai";
import { Sidebar } from "./sidebar/Sidebar";
import Contacts from "./Contacts";
import Direct from "./Direct";
import usePushNotifications from "./usePushNotifications";
import { useHistory, useParams } from "react-router-dom";
import Meta from "./Meta";
import ValidationDay from "./ValidationDay";
import { SocialLinks } from "./SocialLinks";
import { SocialLink } from "./SocialLink";
import Creatable, { useCreatable } from "react-select/creatable";
import { Bell, Menu as MenuIcon } from "react-feather";
import { useChatProvider } from "./ChatClient";
import { AnalyticsReport } from "./AnalyticsReport";
import Tools from "./Tools";
import Gallerymaker from "./Gallerymaker";

// Provide stable login and JWT
export default () => {
  const { loginWithRedirect, getAccessTokenSilently, user } = useAuth0();
  const [jwt, setJWT] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        console.log(token);
        setJWT(token);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently]);

  return (
    <>
      {!jwt && (
        <div
          className="py-4 px-8 text-white bg-gray-800 mx-4 my-4 cursor-pointer inline-block"
          onClick={() => loginWithRedirect()}
        >
          Zaloguj się
        </div>
      )}
      {jwt && <GQLProvider jwt={jwt} user={user} />}
    </>
  );
};

// Provide GQL client
const GQLProvider = ({ jwt, user: auth0User }) => {
  const client = createClient({
    url: `${getEnv("url")}/graphql`,
    fetchOptions: {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  });

  return (
    <Provider value={client}>
      <AtomProvider auth0User={auth0User} />
    </Provider>
  );
};

const CatchAll = () => {
  const { article } = useParams();
  if (article.split("-").length > 1) {
    const dividedArticle = article.split("-");
    window.location.href =
      "/articles/" + dividedArticle[dividedArticle.length - 1];
  }
  return null;
};

const MainPart = () => {
  return (
    <>
      <Route path="/direct/:id" component={Direct} />

      <Route path="/tags" component={Tags} exact />
      <Route path="/tags/:id" component={Tag} />

      <Route path="/articles" component={Articles} exact />
      <Route path="/articles/:id" component={Article} />
      <Route path="/article_magic" component={ArticleMagic} exact />

      <Route path="/snippets" component={Snippets} exact />
      <Route path="/snippets/:id" component={Snippet} />

      <Route path="/comments" component={Comments} />
      <Route path="/commentsModerated" component={Moderated} />
      <Route path="/dictionary" component={Dictionary} />

      <Route path="/polls" component={Polls} exact />
      <Route path="/polls/:id" component={Poll} />

      <Route path="/authors" component={Authors} exact />
      <Route path="/authors/:id" component={Author} />

      <Route path="/newsletter" component={Newsletter} />

      <Route path="/contacts" component={Contacts} exact />
      <Route path="/contacts/:id" component={Contact} />

      <Route path="/social_links" component={SocialLinks} exact />
      <Route path="/social_links/:id" component={SocialLink} />

      <Route path="/issues" component={Issues} exact />
      <Route path="/issues/:id" component={Issue} />

      <Route path="/:article" component={CatchAll} exact />

      <Route path="/metas/:id" component={Meta} exact />
      <Route path="/validation/:date" component={ValidationDay} />

      <Route path="/analytics_report" component={AnalyticsReport} />
      <Route path="/tools" component={Tools} />
      <Route path="/gallery" component={Gallerymaker} />
    </>
  );
};

// Provide global data
const AtomProvider = ({ auth0User }) => {
  const history = useHistory();
  const [everythingLoaded, setEverythingLoaded] = useState(false);

  const [users, setUsers] = useAtom(usersAtom);
  const [user, setUser] = useAtom(userAtom);
  const [tags, setTags] = useAtom(tagsAtom);
  const [authors, setAuthors] = useAtom(authorsAtom);
  const [reklamodawcy, setReklamodawcy] = useAtom(reklamodawcyAtom);
  const [windowWidth] = useAtom(windowWidthAtom);

  window.sendNotification = (notification) => {
    history.push(JSON.parse(notification).data.path);
  };

  // useEffect(() => {
  //   (async () => {
  //     const permissionState = await getNotificationPermissionState();
  //     console.log("[permissionState]", permissionState);

  //     if (permissionState === "prompt") {
  //       const permissionAsk = await askPermission();
  //       console.log("[permissionAsk]", permissionAsk);
  //     } else if (permissionState === "granted") {
  //       console.log("GRANTED");
  //       await subscribeUserToPush();
  //     }
  //   })();
  // }, []);

  const [{ data }, _] = useQuery({
    query: `
  {
    admins {
      id
      name
      email
      active
    }
    tags {
      name
      genre
      id
    }
    authors(where: {
      author: {
        equals: 1
      }
    }) {
      fname
      id
    }

    reklamodawcy: groupByArticle(by: [reklamodawca]) {
      _count {
        reklamodawca
      }
      reklamodawca
    }
  
  }
  `,
  });

  const [_1, saveNotifications] = useMutation(`mutation ($nstring: String!) {
    updateAdmin(where: {
      id: ${user.id}
    }, data: {
      notifications: {
        set: $nstring
      }
    }) {
      id
    }
  }`);

  useEffect(() => {
    if (!data) return () => {};
    setUsers(data.admins);
    setUser(data.admins.find((el) => el.email === auth0User.email));
    setTags(data.tags);
    setAuthors(data.authors);
    setReklamodawcy(data.reklamodawcy);
  }, [data]);

  useEffect(() => {
    if (everythingLoaded && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("pushToken");
    }
  }, [everythingLoaded]);

  if (!users || !user || !tags || !authors || !reklamodawcy) return null;

  window.sendPushToken = (token) => {
    saveNotifications({
      nstring: token,
    });
  };

  if (!everythingLoaded) setEverythingLoaded(true);

  if (windowWidth > 1100) return <PCGrid />;
  return <MobileGrid />;
};

const MobileGrid = () => {
  const [notifications, setNotifications] = useState(false);
  const [menu, setMenu] = useState(false);
  return (
    <>
      <div
        className="fixed w-screen z-0 overflow-y-scroll"
        style={{
          height: "calc(100vh - 64px)",
        }}
      >
        <MainPart />
      </div>
      <div
        className="fixed bottom-0 right-0 p-4 w-16 h-16 z-20 bg-white border-2 flex justify-center items-center"
        onClick={() => {
          setMenu((e) => !e);
        }}
      >
        <MenuIcon />
      </div>
      <div
        className={
          "fixed w-screen h-screen z-10 " + (menu ? "left-0" : "left-full")
        }
      >
        <Menu />
      </div>
    </>
  );
};

const PCGrid = () => {
  const [hidden, setHidden] = useAtom(chatHiddenAtom);
  return (
    <div>
      <div
        className="fixed top-0 left-0 right-0 z-10 bg-gray-800"
        style={{
          height: 96,
        }}
      >
        <Menu />
      </div>
      <div
        className="transition-all fixed"
        style={{
          top: 96,
          left: 0,
          bottom: 0,
          right: 0,
          width: `calc(100vw)`,
          overflowY: "scroll",
        }}
      >
        <MainPart />
      </div>
    </div>
  );
};
