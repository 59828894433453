export default () => {
  return (
    <div>
      <form
        action="https://rembg.dev.krzysztofsikorski.pl"
        method="post"
        enctype="multipart/form-data"
      >
        <input type="file" name="file" />
        <input type="submit" value="upload" />
      </form>
    </div>
  );
};
