const envs = {
  normal: {
    url: "https://api.histmag.org",
    ws: "wss://websocket.histmag.org",
  },
  local: {
    url: "http://localhost:4000",
    // ws: "ws://localhost:4001"
    ws: "wss://websocket.histmag.org",
  },
};

export const getEnv = (key) => {
  const envsetName = window.localStorage.getItem("envset") || "normal";
  const envset = envs[envsetName];
  return envset[key];
};
