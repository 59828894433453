import { Input, Select, Submit, Textarea } from "./services/components";
import { useGQLForm } from "./services/hooks";
import { useEffect } from "react";
import { ChatBox } from "./chatbox/ChatBox";
import { Observe } from "./chatbox/Observe";
import { useAtom } from "jotai";
import { zipObj } from "ramda";
import { usersAtom } from "./services/atoms";

const DataQuery = `
query ($id: Int!) {
    contact(where: {
        id: $id
    }) {
      id
      name
      wiki
    }
  }
`;

const CreateQuery = ``;

const UpdateQuery = `
mutation UpdateContact($updateData: ContactUpdateInput!, $id: Int!) {
    updateContact(data: $updateData, where: {
        id: $id
    }) {
      id
    }
  }
`;

// const parseData = (users) => (data) => {
//   const newData = parseTasks(users)(data);
//   return {
//     ...newData,
//   };
// };

export default () => {
  const [users] = useAtom(usersAtom);
  const {
    register,
    handleSubmit,
    onSubmit,
    error,
    data,
    idExists,
    setValue,
    getValues,
  } = useGQLForm({
    DataQuery,
    CreateQuery,
    UpdateQuery,
    createKey: "createAuthor",
    path: "authors",
  });

  if (error || (!data && idExists)) return <div>Loading...</div>;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex">
        <div className="w-2/3">
          <div className="container px-4 mx-auto py-8 ">
            <Input
              label="Nazwa"
              placeholder="Wpisz nazwę"
              {...register("name")}
              defaultValue={idExists ? data.contact.name : ""}
            />

            {/* <Article data={{}} change={() => {}} /> */}
            {/* <Editor
              value={data.contact.wiki || undefined}
              change={(value) => setValue("wiki", { unset: true, value })}
              users={users}
            /> */}

            <Submit />
          </div>
        </div>
        <div className="w-1/3">
          <Observe
            parent_id={data.contact.id}
            parent_type="contacts"
            getName={() => getValues("name")}
          />
          <ChatBox parent_id={data.contact.id} parent_type="contacts" />
        </div>
      </div>
    </form>
  );
};
