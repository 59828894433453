import dayjs from "dayjs";
import { useAtom } from "jotai";
import { useState } from "react";
import { useQuery, useMutation } from "urql";
import { usersAtom } from "./services/atoms";

const Comments = `query Moderated($search: String!, $limit: Int){

    comments(where: {
      moderated: {
          equals: 1
      }
      content: {
        contains: $search
      },
  }, orderBy: {
      created_at: desc
  }, take: $limit) {
      id
      nick
      content
      post
      ip
      created_at
      shown
      moderated_by
      postRel {
        title
      }
  }
}`;

const UnModerateComment = `mutation UnModerateComment($commentId: Int!) {
    updateComment(data: {
        moderated: {
            set: 0
        }
        shown: {
          set: 0
        }
    }, where: {
        id: $commentId
    }) {
      id
    }
  }`;

export default () => {
  const [query, setQuery] = useState("");
  const [users] = useAtom(usersAtom);

  const [{ data, error }] = useQuery({
    query: Comments,
    variables: {
      search: query,
      limit: query ? 10 : 1000,
    },
  });

  const [_, unModerateComment] = useMutation(UnModerateComment);

  if (!data) return <div>Ładowanie...</div>;
  return (
    <div className="flex">
      <div className="w-full">
        <input
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className="border-2"
        />
        {data.comments.map((comment) => (
          <div className={comment.shown == "1" ? "bg-green-300" : "bg-red-300"}>
            Data: {dayjs.utc(comment.created_at).format("YYYY-MM-DD HH:mm")}
            <br />
            Nick: {comment.nick}
            <br />
            Shown: {comment.shown}
            <br />
            IP: {comment.ip}
            <br />
            <a target="_blank" href={`https://histmag.org/?id=${comment.post}`}>
              {comment.postRel && comment.postRel.title}
            </a>
            <br />
            Content: <br />
            {comment.content}
            <br />
            <br />
            <button
              className="border-2 p-4"
              onClick={() =>
                unModerateComment({
                  commentId: comment.id,
                })
              }
            >
              unmoderate
            </button>
            <br />
            <br />
            Moderowane przez:
            {users.find((user) => user.id == comment.moderated_by)?.name}
            <br />
            <hr />
            <br />
          </div>
        ))}
      </div>
    </div>
  );
};
