import { Link } from "react-router-dom";
import { useQuery } from "urql";
import { useEffect } from "react";
import { userAtom, windowWidthAtom } from "./services/atoms";
import { useAtom } from "jotai";

const MenuLink = ({ text, link }) => (
  <li>
    <Link
      className="flex items-center pl-3 py-3 pr-4 text-gray-50 rounded"
      to={link}
    >
      <span>{text}</span>
    </Link>
  </li>
);

const CommentsQuery = `{
  aggregateComment(where: {
    moderated: {
      equals: 0
    }
  }) {
    _count {
      _all
    }
  }
}`;

export default () => {
  const [user] = useAtom(userAtom);
  const [windowWidth] = useAtom(windowWidthAtom);
  const [{ data: commentsData }, refetchComments] = useQuery({
    query: CommentsQuery,
    requestPolicy: "network-only",
  });

  useEffect(() => {
    setInterval(() => {
      refetchComments();
    }, 3000);
  }, []);

  if (!commentsData) return null;
  return (
    <>
      <nav className="absolute inset-0 bg-gray-800 pb-6 pt-6 pr-6 flex items-center">
        {windowWidth > 1100 && (
          <div className="flex-shrink-0 flex text-white font-semibold w-64 px-6">
            <div>
              <img className="h-10 mr-4" src="/logo.png" alt="" width="auto" />
            </div>
            <div
              style={{
                marginTop: -4,
              }}
            >
              <div className="text-lg">Histmag.org</div>
              <div className="text-sm">{user.name}</div>
            </div>
          </div>
        )}
        <ul className="flex flex-col lg:flex-row">
          <MenuLink text="Artykuły" link="/articles" />
          <MenuLink
            text={`Komentarze ${commentsData.aggregateComment._count._all}`}
            link="/comments"
          />
          <MenuLink text="Snippety" link="/snippets" />
          <MenuLink text="Sondy" link="/polls" />
          <MenuLink text="Tagi" link="/tags" />
          <MenuLink text="Autorzy" link="/authors" />
          <MenuLink text="Dane" link="/dictionary" />
          <MenuLink text="Newsletter" link="/newsletter" />
          <MenuLink text="Galeria" link="/gallery" />
        </ul>
      </nav>
    </>
  );
};
