const htmlPage = `
<!DOCTYPE html>
<html>
    <head>
        <title>Narzędzie galerii</title>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.0.0-alpha/css/bootstrap.min.css">
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.5.0/css/font-awesome.min.css">

        <meta charset="UTF-8">
    </head>
    <body>

        <div class="hidden" style="display:none">
          <div class="row image">

            <div class="col-xs-6">
              Link do obrazka: <input type="text" class="form-control img_link">
            </div>


            <div class="col-xs-5">
              Opis: <textarea class="form-control desc"></textarea>
            </div>

            <div class="col-xs-1">
              <br/>
              <div class="btn btn-warning delete">usuń</div>
            </div>


            <hr style="clear:both;margin-top:100px;"/>
          </div>
        </div>

        <div class="container">
          <br/>
          <h1>Histmag gallery maker</h1>
          <br/>
          <div class="images">

          </div>

          <div class="add_new btn btn-success">Dodaj nowy</div>
          <div class="generate btn btn-primary">Generuj</div>

          <textarea class="code form-control" style="display:none"></textarea>
        </div>

        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.1/jquery.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.0.0-alpha/js/bootstrap.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jqueryui/1.12.1/jquery-ui.min.js"></script>

        <script>
        
        const galleryAlready = window.localStorage.getItem('oldGallery') || '[]';
        const galleryArray = JSON.parse(galleryAlready)

        for (const image of galleryArray) {
          $('.images').append($('.hidden').html());
          $('.images .image').last().find('.img_link').val(image.img_link);
          $('.images .image').last().find('.desc').val(image.desc);
        }

        setInterval(() => {
          let arr = [];
          const currentGallery = $('.images div.image').map(function( index, element ) {
            arr.push({
              img_link: $(element).find('.img_link').val(),
              desc:$(element).find('.desc').val()
            })
          })
          window.localStorage.setItem('oldGallery', JSON.stringify(arr))

        }, 1000)

          $( ".images" ).sortable();

          $('.add_new').click(function(){
            $('.images').append($('.hidden').html());
          });

          $( ".images" ).on( "click", "div.delete", function( event ) {
              event.preventDefault();
              $(this).parent().parent().remove();
          });

          $('.generate').click(function(){
            var code = '[GALLERY]';

            $('.images div.image').each(function( index ) {

              if (code != '[GALLERY]')
                code += '@@@';

              var img_link = $(this).find('.img_link').val();
              var desc = $(this).find('.desc').val();
              
              const realLink = img_link.replace('https://fs.new.histmag.org/view', 'https://histmag.org')

              code += realLink + ';;;' + realLink + ';;;' + desc;
            });

            $('.code').show();
            $('.code').val(code);

          });


        </script>
    </body>
</html>`;

export default () => {
  return (
    <>
      <iframe
        className="w-full"
        style={{
          height: 2000,
        }}
        srcdoc={htmlPage}
      />
    </>
  );
};
